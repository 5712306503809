import React from 'react';

export const DefaultProfileImage = ({ first_name, last_name, classValue }) => {
  return (
    <div
      className={`self-center flex justify-center items-center ${
        classValue ? classValue : 'w-[50px] h-[50px]'
      } rounded-full uppercase`}
      style={{ background: '#E3F7FC' }}
    >
      <span style={{ color: '#177E9C' }}>{first_name && first_name.charAt(0)}</span>
      <span style={{ color: '#177E9C' }}>{last_name && last_name.charAt(0)}</span>
    </div>
  );
};
