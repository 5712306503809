import React from 'react';

export default function TagListing({ list, isRemove, deleteCapsule, isDisabled = false, tagsName }) {
  return (
    <div className='text-left mt-2 flex gap-2 pb-0.5 flex-wrap max-h-[150px] overflow-auto pr-[5px]'>
      {list?.map((value, i) => {
        const shouldRenderButton = (tagsName === 'network' && value?.id != 1) || tagsName !== 'network';

        return (
          <span
            key={i}
            className={`inline-flex items-center gap-x-0.5 rounded-md text-hexitime-primary px-2 py-1 text-md font-medium bg-hexitime-lightPrimary ${
              !isDisabled && 'hover:bg-hexitime-primary hover:text-white group'
            } whitespace-pre`}
          >
            {value?.name}

            {shouldRenderButton && isRemove && !isDisabled && (
              <button
                type='button'
                className='relative ml-2 -mr-1 h-3.5 w-3.5 rounded-sm'
                onClick={() => deleteCapsule(value)}
              >
                <span className='sr-only'>Remove</span>
                <svg viewBox='0 0 14 14' className='h-3.5 w-3.5 stroke-hexitime-primary group-hover:stroke-white'>
                  <path d='M4 4l6 6m0-6l-6 6' />
                </svg>
                <span className='absolute -inset-1' />
              </button>
            )}
          </span>
        );
      })}
    </div>
  );
}
