import React from 'react';
import { classNames } from '../../../utils/helper';

const SmallTabs = ({ tabs, setTabs }) => {
  const handleTabClick = (clickedTab) => {
    const updatedTabs = tabs?.map((tab) => {
      if (tab.name === clickedTab.name) {
        return { ...tab, current: true };
      } else {
        return { ...tab, current: false };
      }
    });

    setTabs(updatedTabs);
  };

  return (
    <>
      <div className='block'>
        <nav className='flex gap-3.5' aria-label='Tabs'>
          {tabs?.map((tab) => (
            <button
              key={tab.name}
              onClick={() => handleTabClick(tab)}
              className={classNames(
                tab.current
                  ? 'bg-hexitime-secondary text-white font-semibold'
                  : 'text-hexitime-textColor3 border border-hexitime-textColor4 hover:text-hexitime-secondary hover:!border-hexitime-secondary',
                'rounded-md px-2.5 sm:py-1.5 py-1 font-medium text-[17px] whitespace-nowrap relative',
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab?.name}
              {tab?.notification > 0 && (
                <p className='absolute !rounded-full !h-6 !w-6 !bg-hexitime-primaryRed top-[-10px] right-[-7px] !p-1 !text-[0.75rem] !text-white !shadow-sm !focus-firstVisible:outline !flex !items-center !justify-center !ml-2'>
                  {tab?.notification > 99 ? '99+' : tab?.notification}
                </p>
              )}
            </button>
          ))}
        </nav>
      </div>
    </>
  );
};

export default SmallTabs;
