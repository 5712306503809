import * as React from 'react';
import PropTypes from 'prop-types';
import EyeIcon from '../../../assets/images/eye.svg';
import EyeSlashIcon from '../../../assets/images/eye-slash.svg';
import { useState } from 'react';

const CommonInput = ({
  id,
  name,
  value,
  label,
  error,
  type,
  onChange,
  disabled,
  isIcon,
  placeholder,
  classNames,
  onClick,
  dateInputRef,
  max,
  maxLength,
  isRequired,
}) => {
  const [showEyeIcon, setShowEyeIcon] = useState(false);

  return (
    <div className='text-left'>
      <label htmlFor={id} className='block text-sm xl:text-base font-medium text-hexitime-textColor1'>
        {label} {isRequired && !disabled && <span className='text-hexitime-primaryRed'>&#42;</span>}
      </label>
      <div className='relative'>
        <div className='mt-2 mb-1'>
          <input
            id={id}
            name={name}
            value={value}
            type={showEyeIcon ? 'text' : type}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            className={`block w-full rounded-lg appearance-none border ${
              classNames ? classNames : 'px-[17px] 2xl:py-4 sm:py-3 py-2'
            } ${
              error ? 'border-hexitime-primaryRed' : 'border-hexitime-textColor4'
            } placeholder-hexitime-textColor2 focus:border-hexitime-primary focus:outline-none focus:ring-hexitime-primary sm:text-sm`}
            onClick={onClick ? onClick : () => {}}
            ref={dateInputRef ? dateInputRef : null}
            max={max ? max : undefined}
            onKeyDown={type === 'date' ? (e) => e.preventDefault() : () => {}}
            maxLength={maxLength && maxLength}
            autoComplete={type === 'password' ? 'new-password' : 'off'}
          />
        </div>
        {isIcon &&
          (showEyeIcon ? (
            <img
              src={EyeIcon}
              onClick={() => setShowEyeIcon(false)}
              className={`cursor-pointer absolute w-[20px] top-1/2 transform -translate-y-1/2 ${
                classNames ? 'right-[13px]' : 'right-[10px]'
              }`}
              alt='eye-icon'
            />
          ) : (
            <img
              src={EyeSlashIcon}
              onClick={() => setShowEyeIcon(true)}
              className={`cursor-pointer absolute w-[20px] top-1/2 transform -translate-y-1/2 ${
                classNames ? 'right-[13px]' : 'right-[10px]'
              }`}
              alt='eye-slash-icon'
            />
          ))}
      </div>
      {error && <span className='error text-sm xl:text-base text-hexitime-primaryRed leading-[1px]'>{error}</span>}
    </div>
  );
};

CommonInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  classNames: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isIcon: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};
export default CommonInput;
