import axios from 'axios';
import { cleanLocalStorage, errorToast, getJWTToken } from '../utils/helper';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://192.168.1.215:8000/api/v1';

let tokenExpiredErrorShown = false;

const GetApi = (tag = '', isHeader = false, cancelToken) => {
  return axios
    .get(BASE_URL + tag, {
      headers: isHeader
        ? {
            Authorization: getJWTToken(),
          }
        : {},
      cancelToken,
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      if (!cancelToken) {
        ErrorHandler(e);
      }
    });
};

const PostApi = (tag = '', reqBody, isHeader = false, flag) => {
  let flagCheck = flag ? 'multipart/form-data; boundary=----WebKitFormBoundaryueI4YvrqiXxUgVGA' : 'application/json';

  return axios
    .post(BASE_URL + tag, reqBody, {
      headers: isHeader
        ? {
            'Content-Type': flagCheck,
            accept: 'application/json',
            Authorization: getJWTToken(),
          }
        : {},
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      ErrorHandler(e);
    });
};

const PutApi = (tag = '', reqBody, isHeader = false, flag) => {
  let flagCheck = flag ? 'multipart/form-data; boundary=----WebKitFormBoundaryueI4YvrqiXxUgVGA' : 'application/json';

  return axios
    .put(BASE_URL + tag, reqBody !== null && reqBody, {
      headers: isHeader
        ? {
            'Content-Type': flagCheck,
            accept: 'application/json',
            Authorization: getJWTToken(),
          }
        : {},
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      ErrorHandler(e);
    });
};

const DeleteApi = (tag = '', isHeader = false, data) => {
  return axios
    .delete(BASE_URL + tag, {
      headers: isHeader
        ? {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: getJWTToken(),
          }
        : {},
      data: data && data,
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      ErrorHandler(e);
    });
};

const ErrorHandler = async (e) => {
  if (e.response?.data?.detail?.message) {
    if (e.response?.data?.detail?.code === 401) {
      if (!tokenExpiredErrorShown) {
        tokenExpiredErrorShown = true;
        errorToast(e.response?.data?.detail?.message);
        cleanLocalStorage();
        window.location.href = '/';
      }
    } else {
      errorToast(e.response?.data?.detail?.message);
    }
  } else if (e.response?.status === 500) {
    errorToast(e.response?.data || 'Internal server error');
  } else if (e?.code === 'ERR_NETWORK') {
    errorToast(e?.message || 'Network Error');
  } else {
    errorToast(e.response?.data?.meta?.message || 'Something went wrong');
  }
};

export const Api = {
  // Social Register/Login
  socialAuth: (reqBody) => PostApi('/auth/social-auth-validation', reqBody),
  whiteLabelUserLogin: (access_token) => PostApi(`/auth/whitelabel/user/login?access_token=${access_token}`, null),

  // Step 1
  register: (reqBody) => PostApi('/auth/user-account', reqBody),

  // Step 2
  verifyOTP: (reqBody) => PostApi('/auth/verify-otp', reqBody),
  resendCode: (reqBody) => PostApi('/auth/resend-otp', reqBody),

  // Step 3
  createProfile: (reqBody) => PostApi('/auth/user-profile', reqBody, true, true),

  // Step 4
  skillsList: (searchKey) => GetApi(`/common/skills?search=${searchKey}`),
  interestsList: (searchKey) => GetApi(`/common/interests?search=${searchKey}`),
  skillsInterests: (reqBody) => PostApi('/user/skills-interests-locations', reqBody, true),
  createSkill: (reqBody) => PostApi('/common/skill', reqBody, true),
  createInterest: (reqBody) => PostApi('/common/interest', reqBody, true),

  // Step 5
  demographics: (reqBody) => PutApi('/user/demographics', reqBody, true),

  // Step 6
  followList: () => GetApi('/user/follow-list', true),
  followUnfollow: (reqBody, user_id, profile_id) =>
    PostApi(`/user/follow-unfollow?user_id=${user_id}&profile_id=${profile_id}`, reqBody, true),
  stepsCompleted: () => GetApi('/auth/continue-to-login', true),

  //LRF FLOW APIs
  login: (reqBody) => PostApi('/auth/login', reqBody, true, true),
  forgotPassword: (reqBody) => PostApi('/auth/forgot-password', reqBody),
  resetPassword: (reqBody) => PostApi('/auth/new-password', reqBody),
  changePassword: (reqBody) => PutApi('/auth/change-password', reqBody, true),

  // Profile API
  accountDetails: (id) => GetApi(`/user/account/details?user_id=${id}`, true),

  //settings-account
  changeEmail: (reqBody) => PostApi('/user/change-email', reqBody, true),
  verifyNewEmail: (reqBody) => PostApi('/user/verify-new-email', reqBody, true),
  generateNewOTP: () => GetApi('/user/resend-otp', true),
  accountPersonalInfo: (reqBody) => PutApi('/user/account/personal-info', reqBody, true),

  //settings-profile
  profilePersonalInfo: (reqBody) => PutApi('/user/profile/personal-info', reqBody, true),
  profileAboutInfo: (reqBody) => PutApi('/user/profile/about-info', reqBody, true),
  profilePicture: (reqBody) => PutApi('/user/profile-picture', reqBody, true, true),
  profileSkills: (reqBody) => PutApi('/user/skills', reqBody, true),
  profileInterests: (reqBody) => PutApi('/user/interests', reqBody, true),
  followersList: (user_id) => GetApi(`/user/get-follower-user/${user_id}`, true),
  followingList: (user_id) => GetApi(`/user/get-following-user/${user_id}`, true),

  //settings-alert
  userAlerts: (reqBody) => PutApi('/user/alerts', reqBody, true),

  //get users details for chat list
  getChatUsersData: (reqBody) => PostApi('/user/get-users-profile', reqBody, true),

  //get credit history
  getAllCreditHistory: (sortBy, sortOrder) =>
    GetApi(`/user/user-credit-history?user_order_by=${sortBy}&order_flag=${sortOrder}`, true),

  // get random users
  getRandomUsers: () => GetApi('/user/random-user-list', true),

  //create offer/request/thread
  createContentDraft: (reqBody, networkId) =>
    PostApi(`/contents/create-content-draft?network_id=${networkId}`, reqBody, true),
  createContent: (reqBody) => PostApi('/contents/create-content', reqBody, true),

  getAllDrafts: (page, limit, networkId) =>
    GetApi(`/contents/get-draft-list?page=${page}&limit=${limit}&network_id=${networkId}`, true),

  getAllContent: (userId, contentType, page, version, limit, contentId, cancelToken) =>
    GetApi(
      `/contents/get-contentBy-user-id?user_id=${userId}&content_type=${contentType}&page=${page}&version=${version}&limit=${limit}&${
        contentId ? `content_id=${contentId}` : ''
      }`,
      true,
      cancelToken,
    ),
  getContent: (content_id) => GetApi(`/contents/get-content?content_id=${content_id}`, true),
  updateContent: (reqBody, content_id, content_version, networkId) =>
    PutApi(
      `/contents/update-content?content_id=${content_id}&content_version=${content_version}&network_id=${networkId}`,
      reqBody,
      true,
    ),
  deleteContent: (contentId, content_type, version) =>
    DeleteApi(`/contents/delete-content?content_id=${contentId}&content_type=${content_type}&version=${version}`, true),
  likeContent: (content_id) => PostApi(`/contents/add-content-vote?content_id=${content_id}`, {}, true),

  getVotesList: (content_id, page, limit) =>
    GetApi(`/contents/get-voted-userlist?content_id=${content_id}&page=${page}&limit=${limit}`, true),
  addComment: (reqBody) => PostApi('/contents/add-comment', reqBody, true),
  deleteComment: (contentId, comment_id) =>
    DeleteApi(`/contents/delete-comment?content_id=${contentId}&comment_id=${comment_id}`, true),

  getCommentsList: (content_id, page, limit) =>
    GetApi(`/contents/get-comment-list?content_id=${content_id}&page=${page}&limit=${limit}`, true),
  voteComment: (comment_id) => PostApi(`/contents/add-comment-vote?comment_id=${comment_id}`, {}, true),
  commentVotesList: (comment_id, page, limit) =>
    GetApi(`/contents/get-comment-voted-userlist?comment_id=${comment_id}&page=${page}&limit=${limit}`, true),
  uploadContentAttachments: (reqBody) => PostApi('/contents/upload-content-attachment', reqBody, true),

  deleteContentFile: (payload) =>
    DeleteApi(`/contents/delete-content-attachment`, true, {
      delete_attechments: payload,
    }),

  //home page
  getHomeContent: (limit, contentType, page) =>
    GetApi(`/contents/home-page-feed?limit=${limit}&content_type=${contentType}&page=${page}&network_id=${1}`, true),

  searchAll: (title) => GetApi(`/contents/search?title=${title}`, true),
  searchContent: (limit, page, title, contentType, networkId) =>
    GetApi(
      `/contents/searchBycontentType?title=${title}&limit=${limit}&content_type=${contentType}&page=${page}&network_id=${networkId}`,
      true,
    ),
  searchUser: (limit, page, title) => GetApi(`/contents/searchUser?title=${title}&limit=${limit}&page=${page}`, true),

  //get updates and alerts
  getAllUpdatesAlerts: () => GetApi('/contents/get-user-activity-alerts', true),

  // get random contents
  getRandomContents: () => GetApi('/contents/get-random-content-list', true),

  // scheduled activities
  acceptContent: (reqBody) => PostApi('/exchanges/acceptContent', reqBody, true),
  updateScheduleActivity: (reqBody) => {
    return PutApi('/exchanges/update-schedule-activity', reqBody, true);
  },
  getStatusActivity: (status, limit, page) =>
    GetApi(`/exchanges/get-status-activity-list?status=${status}&limit=${limit}&page=${page}`, true),
  updateActivityStatus: (reqBody) => PutApi('/exchanges/update-status-activity', reqBody, true),
  getScheduleActivityCounts: () => GetApi('/exchanges/get-user-schedule-activity', true),

  // networks
  getAllNetworksList: () => GetApi('/network/get-network-list-follow-by-user', true),
  getNetworkFollowers: (networkId) => GetApi(`/network/get-network-follower-list?network_id=${networkId}`, true),
  getNetworkMembers: (networkId, role) =>
    GetApi(`/network/get-network-members-list?network_id=${networkId}&role=${role}`, true),
  getNetworkDetail: (networkId) => GetApi(`/network/get-network?network_id=${networkId}`, true),
  networkFollowUnfollow: (reqBody) => PostApi('/network/network-follow-unfollow', reqBody, true),
  networkJoinRequest: (reqBody) => PostApi('/network/network-user-relation', reqBody, true),
  networkPendingList: (networkId) =>
    GetApi(`/network/network-user-relation-pending-list?network_id=${networkId}`, true),
  networkSingleApproveDecline: (reqBody) => PostApi('/network/network-user-relation-approved-decline', reqBody, true),
  networkAllApprove: (reqBody, networkId) =>
    PostApi(`/network/approved-all-request-to-join-network?network_id=${networkId}`, reqBody, true),
  updateNetworkLogo: (reqBody) => PutApi('/network/update-network-logo', reqBody, true),
  updateNetworkBanner: (reqBody) => PostApi('/network/upload-network-image', reqBody, true),
  updateNetwork: (reqBody) => PutApi('/network/update-network', reqBody, true),
  leaveNetwork: (networkId) => DeleteApi(`/network/leave-network?network_id=${networkId}`, true),

  removeMemberNetwork: (networkId, uid) =>
    DeleteApi(`/network/remove-user-from-network?network_id=${networkId}&user_id=${uid}`, true),

  getNetworksList: () => GetApi(`/network/get-network-list`, true),
  getUserNetworksList: () => GetApi(`/network/get-user-network-list`, true),

  // network search
  getNetworkContentSearch: (networkId, limit, page, contentType, version, random) =>
    GetApi(
      `/network/get-network-content-list?network_id=${networkId}&content_type=${contentType}&version=${version}&limit=${limit}&page=${page}&is_random=${random}`,
      true,
    ),

  getNetworkUserSearch: (networkId, limit, page) =>
    GetApi(`/network/get-network-user?network_id=${networkId}&limit=${limit}&page=${page}`, true),

  // pinned network content
  getNetworkPinnedContent: (networkId, limit, page) =>
    GetApi(`/contents/get-pinned-content-list?network_id=${networkId}&limit=${limit}&page=${page}`, true),
  getNetworkMembersById: (networkId) => GetApi(`/network/get-users-by-network-id?network_id=${networkId}`, true),

  getNetworkMemberDetailByTheirEmail: (reqBody) => PostApi('/network/get-users_by_emails', reqBody, true),
  pinUnpinContent: (reqBody) => PostApi('/contents/pinned-unpinned-content', reqBody, true),
  findOrCreateUserAndOrganizationInDbIfNotExists: (reqBody) => PostApi('/auth/whitelabel/user/register', reqBody, true),
  checkTokenValidity: (access_token, refresh_token, jwt_token) => {
    return PostApi(
      `/auth/validate-access-token?access_token=${access_token}&refresh_token=${refresh_token}&jwt_token=${jwt_token}`,
      null,
    );
  },
  getOrganizationInfo: () => {
    return GetApi('/auth/whitelabel/org', true);
  },
  getAllRejectedResponsesByFormId: async (formId, networkId) => {
    return GetApi(`/network/get-network-application-decline?google_form_id=${formId}&network_id=${networkId}`, true);
  },
  insertRejectedApplications: async (reqBody) => {
    return PostApi('/network/network-application-decline', reqBody, true);
  },
  getContentPresignedUrlDetail: async (reqBody) => {
    return PostApi('/contents/upload-content-image', reqBody, true);
  },
  deleteSelfAccount: async () => {
    return DeleteApi('/user/self-user-delete', true);
  },
  getUserApplicationStatus: async (pageNumber, limit, cancelToken) =>
    GetApi(`/network/get-network-application-user-status?page=${pageNumber}&limit=${limit}`, true, cancelToken),
  getApplicationDraftByNetworkAndUserId: (networkId, cancelToken) =>
    GetApi(`/network/get-network-application-joiner-draft?network_id=${networkId}`, true, cancelToken),
  getAllUserOfOrg: (page, perPage, searchTerm) =>
    GetApi(`/user/get-organization-all-users?page=${page}&perPage=${perPage}&searchKey=${searchTerm}`, true),
  sendMailForPanelReview: (reqBody) => PostApi(`/network/notify-panel-reviewers`, reqBody, true),
  getGroupProfilePresignedUrlDetail: (reqBody) =>
    PostApi('/network/upload-group-profile-picture', reqBody, true, undefined),
  deleteGroupProfile: (file_url) => DeleteApi(`/network/delete-group-profile-picture?file_url=${file_url}`, true),
};
