const Loader = ({ className }) => {
  return (
    <div
      className={`z-30 flex items-center justify-center !p-5 ${
        className ? className : 'fixed left-0 top-0 right-0 bottom-0 bg-opacity-90 bg-gray-100'
      }`}
    >
      <div className='flex space-x-2 animate-pulse'>
        <div className='w-3 h-3 bg-hexitime-primary rounded-full'></div>
        <div className='w-3 h-3 bg-hexitime-secondary rounded-full'></div>
        <div className='w-3 h-3 bg-hexitime-primary rounded-full'></div>
      </div>
    </div>
  );
};

export default Loader;
