import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DefaultProfileImage } from '../../component/common/DefaultProfileImage';

const UpdatesAlerts = ({ allUpdatesAlerts }) => {
  const alertTitle = (alert) => {
    return (
      <span className='hover:!underline text-[#2f2f2f]'>
        {alert?.title?.length > 30 ? alert?.title?.slice(0, 30) + '...' : alert?.title}
      </span>
    );
  };

  const updateMessage = (alert) => {
    let message = '';

    if (alert?.alert_type === '0') {
      message = (
        <>
          {alert?.content_type} on your request{' '}
          <Link to={`/profile/${alert?.content_type}-detail/${alert?.content_id}`}>"{alertTitle(alert)}"</Link>
        </>
      );
    } else if (alert?.alert_type === '1') {
      message = (
        <>
          commented on your {alert?.content_type}{' '}
          <Link to={`/profile/${alert?.content_type}-detail/${alert?.content_id}`}>"{alertTitle(alert)}"</Link>
        </>
      );
    } else if (alert?.alert_type === '2') {
      message = (
        <>
          liked your {alert?.content_type}{' '}
          <Link to={`/profile/${alert?.content_type}-detail/${alert?.content_id}`}>"{alertTitle(alert)}"</Link>
        </>
      );
    } else if (alert?.alert_type === '3') {
      message = (
        <>
          accepted your {alert?.content_type}{' '}
          <Link to={`/profile/${alert?.content_type}-detail/${alert?.content_id}`}>"{alertTitle(alert)}"</Link>
        </>
      );
    }

    return <span className='!text-sm !font-normal !leading-[18px]'>{message}</span>;
  };

  return (
    <ul className='!overflow-y-auto !max-h-[120px] 1xl:!max-h-[220px] !pl-1'>
      {allUpdatesAlerts?.map((notifi, i) => (
        <li key={i} className={`!relative !flex !justify-between !gap-x-6 !my-4 ${i === 0 && '!mt-0'}`}>
          <div className='!flex !gap-x-2.5 !max-w-[320px]'>
            <Link to={`/profile/${notifi?.user_id}`}>
              {notifi?.profile_picture ? (
                <div className='!h-[18px] !w-[18px]'>
                  <img
                    className='!w-full !flex-none !rounded-full !bg-gray-50 !mt-0.5 !object-cover !object-center !h-full'
                    src={notifi?.profile_picture}
                    alt=''
                  />
                </div>
              ) : (
                <DefaultProfileImage
                  classValue={'!h-[25px] !w-[25px]'}
                  first_name={notifi?.name?.split(' ')[0]}
                  last_name={notifi?.name?.split(' ')[1]}
                ></DefaultProfileImage>
              )}
            </Link>

            <div className='!min-w-0 !flex-auto !self-center'>
              <p className='!leading-[15px] !text-hexitime-textColor1'>
                <Link
                  to={`/profile/${notifi?.user_id}`}
                  className='!text-sm !font-bold !leading-[18px] hover:!underline text-[#2f2f2f]'
                >
                  {notifi?.name}
                </Link>{' '}
                {notifi?.count > 0 && (
                  <span className='!text-sm !font-normal !leading-[18px]'>and {notifi?.count} others</span>
                )}{' '}
                {updateMessage(notifi)}
              </p>
            </div>
          </div>
          <div className='!flex !items-center !gap-x-4 !mr-3'>
            <div className='!hidden sm:!flex sm:!flex-col sm:!items-end !self-end'>
              <p className='!mt-1 !text-sm !text-center !leading-5 !text-gray-500 !whitespace-pre'>
                <span className='!block !leading-none'>{moment.utc(notifi?.updated_at).local().format('DD MMM')}</span>
                <span>{moment.utc(notifi?.updated_at).local().format('hh:mm a')}</span>
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default UpdatesAlerts;
