import PropTypes from 'prop-types';
import React from 'react';
import ButtonSpinner from './ButtonSpinner';

export default function PrimaryButton({
  btnText,
  btnType,
  disabled,
  onClick,
  className,
  btnBGColor,
  Icon,
  isIconComponent = false,
  isLoad,
  iconClass,
  isType,
}) {
  return (
    <>
      <button
        type={btnType}
        disabled={disabled}
        className={`${className && className} ${
          disabled && !isLoad
            ? 'bg-hexitime-textColor3 cursor-not-allowed'
            : `border border-transparent ${
                btnBGColor && btnBGColor === 'secondary'
                  ? 'bg-hexitime-secondary hover:bg-white hover:text-hexitime-secondary border hover:border-hexitime-secondary'
                  : 'bg-hexitime-primary hover:bg-hexitime-darkPrimary'
              } `
        } rounded-lg 2xl:py-3 py-2 px-[20px] lg:px-[50px] xl:px-[72px] sm:text-base text-sm font-normal text-white shadow-sm focus:outline-none w-full whitespace-nowrap ${
          isLoad && disabled ? 'bg-white !border-hexitime-primary hover:bg-white hover:cursor-not-allowed' : ''
        }`}
        onClick={onClick}
      >
        {isLoad ? (
          <ButtonSpinner />
        ) : Icon ? (
          <span className={`flex ${isType ? 'sm:gap-x-2 gap-x-1' : 'sm:gap-x-4 gap-x-2'} justify-center items-center`}>
            {!isIconComponent ? (
              btnText?.indexOf('Report This') !== -1 ? (
                Icon
              ) : (
                <img src={Icon} alt='logo' />
              )
            ) : (
              <div>
                <Icon className={`h-auto ${iconClass ? iconClass : 'w-4'}`} />
              </div>
            )}

            {btnText}
          </span>
        ) : (
          btnText
        )}
      </button>
    </>
  );
}

PrimaryButton.propTypes = {
  btnType: PropTypes.string,
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  isLoad: PropTypes.bool,
  iconClass: PropTypes.string,
};
