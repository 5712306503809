import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { ChatContext, classNames } from '../utils/helper';

const Menu = ({ item, activityCount }) => {
  const location = useLocation();
  const history = useHistory();

  const { setShowChat, setFirstVisible } = useContext(ChatContext);

  const handleRedirect = (link) => {
    setShowChat(false);
    setFirstVisible(null);
    history.push(link);
  };

  return (
    <>
      <div
        key={item.name}
        onClick={() => (item.href !== '#' ? handleRedirect(item.href) : undefined)}
        className={classNames(
          location?.pathname?.includes(item.href)
            ? '!text-hexitime-primary !font-bold'
            : '!text-hexitime-textColor2 hover:!text-hexitime-primary !font-normal',
          ` !group !outline-none !flex !items-center !mr-2 !py-3 text-base !rounded-md !cursor-pointer !first:pt-0`,
        )}
      >
        {item.icon}
        <p className='!flex !whitespace-nowrap'>{item.name}</p>

        {item?.name === 'Scheduled Activities' && parseInt(activityCount) > 0 && (
          <p className='!rounded-full !h-6 !w-6 !bg-hexitime-primaryRed !p-1 !text-[0.75rem] !text-white !shadow-sm !focus-firstVisible:outline !flex !items-center !justify-center !ml-2'>
            {parseInt(activityCount) > 99 ? '99+' : activityCount}
          </p>
        )}
      </div>
    </>
  );
};

Menu.propTypes = {
  item: PropTypes.any,
  i: PropTypes.number,
};

export default Menu;
