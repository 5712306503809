import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ChatContext,
  UserDetailsContext,
  classNames,
  createUserIfEmailNotExist,
  getRandomColor,
  groupMessagesByDate,
  sortByTimestamp,
  useOutsideClick,
  userData,
  truncateDescription,
  errorToast,
} from '../utils/helper';
import { Link, useHistory } from 'react-router-dom';
import { XMarkIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import WriteMessage from './LayoutComponents/WriteMessage';
import {
  Timestamp,
  collection,
  doc,
  endBefore,
  getDocs,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import ButtonSpinner from '../component/common/Buttons/ButtonSpinner';
import { Api } from '../api';
import UpdatesAlerts from './LayoutComponents/UpdatesAlerts';
import Svgs from '../component/common/Svgs';
import PrimaryButton from '../component/common/Buttons/PrimaryButton';
import { ReactComponent as PeopleGroup } from '../assets/images/people_group.svg';
import ChatGroupCreateModal from '../container/Home/Chat/ChatGroupCreateModal';
import ShowGroupMemberModal from '../container/Home/Chat/ShowGroupMemberModal';
import { DefaultProfileImage } from '../component/common/DefaultProfileImage';
import ChatUserListing from '../container/MobileChats/ChatUserListing';
import SmallTabs from '../container/Home/CreateTabs/SmallTabs';

const RightSidebar = () => {
  const { isAccessible, profilePicture, userFirebaseId, setUserFirebaseId, setUserChatDetails } =
    useContext(UserDetailsContext);

  const {
    showChat,
    setShowChat,
    chatWithUser,
    setChatWithUser,
    firstVisible,
    setFirstVisible,
    loading,
    setLoading,
    allConversations,
    setAllConversations,
    flag,
    setFlag,
    isMobileDevice,
    allGroupConversations,
    setAllGroupConversations,
  } = useContext(ChatContext);
  const userD = userData();
  const wrapperRef = useRef(null);
  const history = useHistory();

  const [chatUser, setChatUser] = useState({});
  const [currentChatId, setCurrentChatId] = useState('');
  const [currentChatIndex, setCurrentChatIndex] = useState('');
  const [message, setMessage] = useState('');

  const [usersChat, setUsersChat] = useState([]);
  const [chatWithDate, setChatWithDate] = useState([]);
  const [loader, setLoader] = useState(false);
  const [nextPostsLoading, setNextPostsLoading] = useState(false);
  const [chatLoader, setChatLoader] = useState(false);

  const chatContainerRef = useRef(null);
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState(null);
  const [userUnsubscribe, setUserUnsubscribe] = useState(null);
  const [isScrollTop, setIsScrollTop] = useState(false);
  const [loadNewChat, setLoadNewChat] = useState(false);
  const [shuffledColors, setShuffledColors] = useState(getRandomColor());
  const [allUpdatesAlerts, setAllUpdatesAlerts] = useState([]);
  const [isGroupChatCreateModalOpen, setGroupChatCreateModalOpen] = useState(false);
  const [isShowGroupMembers, setShowGroupMembers] = useState(false);
  const [GroupEdit, setGroupEdit] = useState({});
  const [chatLength, setChatLength] = useState(10);
  const [resizeChat, setResizeChat] = useState({
    size: {
      width: 345,
      height: 503,
    },
    prevCoordinates: {
      x: 0,
      y: 0,
    },
    isResizing: false,
  });

  const tabsArr = [
    { name: 'Conversation', value: 'conversations', notification: 0, current: true },
    { name: 'Group', value: 'general', notification: 0, current: false },
    { name: 'Panel Group', value: 'panelReview', notification: 0, current: false },
  ];
  const [tabs, setTabs] = useState(tabsArr);
  const isGroup = useRef(false);

  useEffect(() => {
    setShuffledColors(getRandomColor());
  }, []);

  useEffect(() => {
    if (!isGroup.current && (showChat || flag) && !isMobileDevice) {
      setChatLoader(true);
      setChatWithDate([]);
      setUsersChat([]);

      const userId = chatWithUser?.user_id ? chatWithUser?.user_id : chatWithUser?.uid;
      if (userId && showChat) {
        setFirstVisible(null);

        const tempDetail = {
          user_id: userId.toString(),
          user_name: chatWithUser?.user_name,
        };
        const fetchUser = async () => {
          const tempFirebase = await createUserIfEmailNotExist(tempDetail, chatWithUser?.profile_picture, true);

          const tempConversation = tempFirebase?.data;
          tempConversation.id = tempFirebase?.firebaseId;

          selectUser(tempConversation || []);
        };
        fetchUser();
      }
    }

    // eslint-disable-next-line
  }, [showChat, flag, chatWithUser?.chatId]);

  const userList = async (isLoad) => {
    if (isLoad) {
      setLoader(true);
    }

    const emailQuery = query(collection(db, 'users'), where('uid', '==', userD?.user_id));

    const userQuerySnapshot = await getDocs(emailQuery);

    if (!userQuerySnapshot.empty) {
      const userConversations = [];
      userQuerySnapshot?.forEach((doc) => {
        userConversations.push({ id: doc.id, ...doc.data() });
      });

      const sortedConversations = sortByTimestamp(userConversations[0]?.conversations, 'updatedAt');

      const users_id_list =
        sortedConversations?.length > 0 ? sortedConversations?.map((conversation) => conversation?.uid) : [];
      getAllChatUserData(users_id_list, sortedConversations);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (userUnsubscribe) {
      userUnsubscribe();
    }

    const newUnsubscribe = onSnapshot(
      query(collection(db, 'users'), where('uid', '==', userD?.user_id?.toString())),
      (snapshot) => {
        const temp = snapshot?.docChanges()[0]?.doc?.data();

        const sortedConversations = sortByTimestamp(temp?.conversations, 'updatedAt');
        const sortedGroupConversations = sortByTimestamp(temp?.groups, 'updatedAt');
        setAllConversations(sortedConversations);
        setAllGroupConversations(sortedGroupConversations);

        let totalUnread = 0,
          unreadGeneral = 0,
          unreadPanelReview = 0;
        totalUnread = sortedConversations?.reduce((sum, conv) => {
          return sum + Number(conv?.unread);
        }, 0);
        unreadGeneral = sortedGroupConversations?.reduce((sum, conv) => {
          if (conv?.groupType == 'general') {
            return sum + Number(conv?.unreadMessageCount);
          }
          return sum;
        }, 0);
        unreadPanelReview = sortedGroupConversations?.reduce((sum, conv) => {
          if (conv?.groupType == 'panelReview') {
            return sum + Number(conv?.unreadMessageCount);
          }
          return sum;
        }, 0);

        setTabs((prev) => {
          return prev.map((tab) => {
            if (tab.value == 'conversations') {
              return { ...tab, notification: totalUnread };
            }
            if (tab.value == 'general') {
              return { ...tab, notification: unreadGeneral };
            }
            if (tab.value == 'panelReview') {
              return { ...tab, notification: unreadPanelReview };
            }
            return tab;
          });
        });
      },
    );

    setUserUnsubscribe(() => newUnsubscribe);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (chatContainerRef?.current?.scrollHeight > 0 && usersChat?.length > 0 && usersChat?.length <= chatLength) {
      scrollToBottom();
    }
    // eslint-disable-next-line
  }, [chatWithDate, chatLoader]);

  const selectUser = async (user) => {
    try {
      setMessage('');
      setChatUser(user);
      setLoading(false);
      setShowChat(true);

      const tempAllConversations = user?.conversations;

      let v = [userFirebaseId, user?.id];
      let v1 = v.sort().join('_');

      setCurrentChatId(v1);

      const index = tempAllConversations.findIndex((e) => e.chatId === v1);

      if (index === -1) {
        const response = await Api.getChatUsersData({ users_id_list: [user?.uid] });
        if (response?.data?.meta?.code === 200) {
          const dbUserDetails = response?.data?.detail[0];
          setDoc(doc(db, 'messages', v1), {
            chatId: v1,
          });

          const currentUser = {
            chatId: v1,
            id: userFirebaseId,
            uid: userD?.user_id,
            name: userD?.user_name,
            unread: 0,
            createdAt: Timestamp.fromDate(new Date()),
            updatedAt: Timestamp.fromDate(new Date()),
            profilePicture: profilePicture,
            isShow: false,
          };

          tempAllConversations.push(currentUser);

          await updateDoc(doc(db, 'users', user?.id), {
            conversations: tempAllConversations,
          });

          const senderData = {
            chatId: v1,
            id: user?.id,
            uid: user?.uid,
            name: dbUserDetails?.name,
            unread: 0,
            createdAt: Timestamp.fromDate(new Date()),
            updatedAt: Timestamp.fromDate(new Date()),
            profilePicture: user?.profilePicture,
            isShow: false,
          };

          const arr = [...(allConversations ? allConversations : []), senderData];

          await updateDoc(doc(db, 'users', userFirebaseId), {
            conversations: arr,
          });
        } else {
          errorToast('something want wrong!');
        }
      }
      await loadChat(v1);

      await userList(false);
    } catch (error) {
      errorToast('something want wrong!');
    }
  };
  const loadChat = async (v1, firstVisibleReset) => {
    let subcollectionRef;
    if (isGroup.current) {
      subcollectionRef = collection(doc(db, 'groups', v1), 'conversations');
    } else {
      subcollectionRef = collection(doc(db, 'messages', v1), 'conversations');
    }

    if (firstVisible && !firstVisibleReset) {
      const previousChatQuery = query(
        subcollectionRef,
        orderBy('createdAt'),
        endBefore(firstVisible),
        limitToLast(chatLength),
      );

      const previousChatQuerySnapshot = await getDocs(previousChatQuery);

      if (previousChatQuerySnapshot?.docs?.length < chatLength) {
        setLoadNewChat(false);
      } else {
        setLoadNewChat(true);
      }

      const tempChats = [];

      previousChatQuerySnapshot?.forEach((doc) => {
        tempChats.push({ id: doc?.id, ...doc.data() });
      });

      setFirstVisible(previousChatQuerySnapshot?.docs[0]);

      // const height = chatContainerRef?.current?.scrollTop;

      if (previousChatQuerySnapshot.docs?.length >= chatLength) {
        chatContainerRef?.current?.scrollTo({
          top: 500,
        });
      }

      setUsersChat((prevData) => [...tempChats, ...prevData]);
      setChatLoader(false);
      setNextPostsLoading(false);
    } else {
      addChatSnapshot(subcollectionRef);
    }
  };

  const addChatSnapshot = async (subcollectionRef) => {
    if (unsubscribe) {
      unsubscribe();
    }
    const newChatQuery = query(subcollectionRef, orderBy('createdAt'), limitToLast(chatLength));

    const newUnsubscribe = onSnapshot(newChatQuery, (snapshot) => {
      if (snapshot.docs?.length < chatLength) {
        setLoadNewChat(false);
      } else {
        setLoadNewChat(true);
      }

      setFirstVisible(snapshot.docs[0]);

      const subcollectionData = [];
      snapshot?.forEach((doc) => {
        subcollectionData.push({ id: doc.id, ...doc.data() });
      });

      setUsersChat(subcollectionData);

      scrollToBottom();

      setChatLoader(false);
      setNextPostsLoading(false);
    });

    setUnsubscribe(() => newUnsubscribe);
  };

  const updateUnread = async (wasGroupChat, currentChatId) => {
    if (isGroup.current || wasGroupChat) {
      if (allGroupConversations?.length > 0 && userFirebaseId && usersChat?.length > 0) {
        const updateUnreadCountOfGroupChat = allGroupConversations?.map((group) => {
          if (group.id === currentChatId) {
            return {
              ...group,
              unreadMessageCount: 0,
            };
          }
          return group;
        });
        await updateDoc(doc(db, 'users', userFirebaseId), {
          groups: updateUnreadCountOfGroupChat,
        });
        setAllGroupConversations(updateUnreadCountOfGroupChat);
      }
    } else {
      if (allConversations?.length > 0 && userFirebaseId && usersChat?.length > 0) {
        const updatedConversations = allConversations?.map((conversation) => {
          if (conversation?.uid === chatUser?.uid) {
            return {
              ...conversation,
              unread: 0,
            };
          }
          return conversation;
        });
        await updateDoc(doc(db, 'users', userFirebaseId), {
          conversations: updatedConversations,
        });
        setAllConversations(updatedConversations);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (unsubscribe) {
        unsubscribe(); // Unsubscribe when the component unmounts
      }
    };
  }, [unsubscribe]);

  const fetchData = async () => {
    const userQuery = query(collection(db, 'users'), where('uid', '==', userD?.user_id));

    const userQuerySnapshot = await getDocs(userQuery);

    if (!userQuerySnapshot.empty) {
      setUserFirebaseId(userQuerySnapshot.docs[0].id || '');
      setUserChatDetails(userQuerySnapshot.docs[0]?.data());
    }
  };

  useEffect(() => {
    const groupedMessages = groupMessagesByDate(usersChat);
    setChatWithDate(groupedMessages);
    // eslint-disable-next-line
  }, [usersChat]);

  useEffect(() => {
    userList(true);
    fetchData();
    // eslint-disable-next-line
  }, []);

  // select user chat when user are shotlisted
  useEffect(() => {
    if (!loader) {
      const tempChat = localStorage.getItem('temp_user_chat') && JSON.parse(localStorage.getItem('temp_user_chat'));

      if (tempChat && userD?.user_id == tempChat?.userId) {
        const userId = tempChat?.userid_with;

        const existingChat = allConversations.find((cov) => {
          return cov.uid == userId;
        });

        if (existingChat) {
          clickUserChat(existingChat);
          localStorage.removeItem('temp_user_chat');
        }
      } else {
        localStorage.removeItem('temp_user_chat');
      }
    }
  }, [loader]);

  const scrollToBottom = async () => {
    chatContainerRef?.current?.scrollTo({
      top: chatContainerRef?.current?.scrollHeight,
      behavior: 'smooth',
    });

    setIsScrollBottom(false);

    if (isGroup.current) {
      const matchedGroupConversation = allGroupConversations?.find(
        (conversation) => conversation?.id === currentChatId,
      );
      if (matchedGroupConversation?.unreadMessageCount > 0) {
        await updateUnread('', currentChatId);
      }
    } else {
      const matchedConversation = allConversations?.find((conversation) => conversation?.uid === chatUser?.uid);

      if (matchedConversation?.unread > 0) {
        await updateUnread();
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrollTop(false);
      setIsScrollBottom(false);

      const containerElement = chatContainerRef.current;
      if (!containerElement) return;

      const scrollTop = containerElement.scrollTop;
      const scrollHeight = containerElement.scrollHeight;
      const clientHeight = containerElement.clientHeight;

      // Calculate the scroll position and determine whether to show or hide the scroll button
      const scrollThreshold = 200; // Adjust this value as needed
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - scrollThreshold;

      setIsScrollBottom(!isAtBottom);

      const isAtTop = scrollTop === 0;

      setIsScrollTop(isAtTop);
    };

    const containerElement = chatContainerRef.current;
    if (containerElement) {
      containerElement.addEventListener('scroll', handleScroll);
    }

    // Cleanup the event listener when the component is unmounted
    return () => {
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (usersChat?.length < chatLength) {
      scrollToBottom();
    }
  }, [chatLength]);

  useEffect(() => {
    if (firstVisible && isScrollTop && loadNewChat) {
      setNextPostsLoading(true);
      loadChat(currentChatId);
    }
    // eslint-disable-next-line
  }, [isScrollTop]);

  const formatTime = (timestampObject) => {
    const timeAgo = moment(timestampObject).format('hh:mm A');

    return timeAgo;
  };

  const sortConversations = (tempCon) => {
    if (isGroup.current) {
      const updatedGroupConversations = allGroupConversations.map((group) => {
        if (group.id === currentChatId) {
          return {
            ...group,
            updatedAt: Timestamp.fromDate(new Date()),
          };
        }
        return group;
      });
      setAllGroupConversations(sortByTimestamp(updatedGroupConversations, 'updatedAt'));
    } else {
      setAllConversations(sortByTimestamp(tempCon, 'updatedAt'));
    }
  };

  const handleCloseChat = async () => {
    const wasGroupChat = isGroup.current;
    isGroup.current = false;
    setShowChat(false);
    setChatWithDate([]);
    setLoader(false);
    setUsersChat([]);
    setIsScrollBottom(false);
    setUnsubscribe(null);
    setIsScrollTop(false);
    setFirstVisible(null);
    const previousCurrentChatId = currentChatId;
    setCurrentChatId('');
    await updateUnread(wasGroupChat, previousCurrentChatId);
  };

  useOutsideClick(wrapperRef, () => {
    if (showChat && !isMobileDevice) {
      handleCloseChat();
    }
  });

  const updateProfilePictures = (userDetails, conversations) => {
    userDetails?.forEach((user) => {
      const conversation = conversations?.find((conv) => parseInt(conv?.uid) === user?.user_id);
      if (conversation) {
        conversation.profilePicture = user?.profile_picture;
        conversation.is_deleted_user = user?.is_user_deleted || !user.is_user_active ? true : false;
      }
    });
    return conversations;
  };

  const getAllChatUserData = (userIds, conversations) => {
    if (userIds?.length > 0) {
      const payload = {
        users_id_list: userIds,
      };
      Api.getChatUsersData(payload)
        .then((response) => {
          if (response?.data?.meta?.code === 200) {
            const updatedConversations = updateProfilePictures(response?.data?.detail, conversations);
            const targetUserIndex = response?.data?.detail?.findIndex(
              (user) => user?.user_id == (chatWithUser?.uid ?? chatWithUser?.user_id),
            );
            setChatWithUser((chatWithUser) => {
              return {
                ...chatWithUser,
                is_deleted_user:
                  response?.data?.detail?.[targetUserIndex] &&
                  (response?.data?.detail?.[targetUserIndex]?.is_user_deleted ||
                    !response?.data?.detail?.[targetUserIndex]?.is_user_active)
                    ? true
                    : false,
              };
            });
            setAllConversations(updatedConversations || []);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    handleCloseChat();
    history.push('/scheduled-activities');
  };
  const clickUserChat = (person) => {
    if (isGroup.current) {
      isGroup.current = false;
    }
    setLoading(true);
    setChatWithUser(person);
    if (chatWithUser?.user_id !== person?.uid || !person) {
      setFlag((flag) => !flag);
    }
    setShowChat(true);
    setFirstVisible(null);
  };
  const getAllUpdatesAlerts = () => {
    Api.getAllUpdatesAlerts().then((response) => {
      if (response?.data?.meta?.code === 200) {
        setAllUpdatesAlerts(response?.data?.detail);
      }
    });
  };

  useEffect(() => {
    getAllUpdatesAlerts();
  }, []);

  const handleClick = () => {
    setGroupEdit({});
    setGroupChatCreateModalOpen(true);
  };

  const clickGroupChat = (group, index) => {
    if (group.id !== currentChatId) {
      setFirstVisible(null);
      isGroup.current = true;
      setCurrentChatId(group.id);
      setChatWithDate([]);
      setShowChat(true);
      setChatLoader(true);
      setUsersChat([]);
      setLoading(false);
      setCurrentChatIndex(index);
      loadChat(group.id, true);
    }
  };

  const handleGroupEdit = (group) => {
    setGroupEdit(group);
    setGroupChatCreateModalOpen(true);
    setShowGroupMembers(false);
  };

  const handleShowMember = () => {
    setShowGroupMembers(true);
  };

  const currectTabValue = () => {
    return tabs?.find((tab) => tab.current).value;
  };

  // ============= for resizing chatbox ===================//
  const handleMouseDown = (e) => {
    setResizeChat((prev) => {
      return {
        ...prev,
        isResizing: true,
        prevCoordinates: {
          x: e.clientX,
          y: e.clientY,
        },
      };
    });
  };

  const handleMouseUp = () => {
    if (resizeChat?.isResizing) {
      setResizeChat((prev) => {
        return {
          ...prev,
          isResizing: false,
        };
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!resizeChat?.isResizing) return;

    const desiredHeight = window.innerHeight - 100;
    const desiredWidth = window.innerWidth - 100;

    const newWidth = Math.min(
      Math.max(resizeChat?.size?.width - e.clientX + resizeChat?.prevCoordinates?.x, 345),
      desiredWidth,
    );
    const newHeight = Math.min(
      Math.max(resizeChat?.size?.height - e.clientY + resizeChat?.prevCoordinates?.y, 503),
      desiredHeight,
    );

    const y = Math.ceil(0.01845 * newHeight - 3.6) + 1; // set chat length according to screen height.
    setChatLength(y);

    setResizeChat((prev) => {
      return {
        ...prev,
        size: {
          width: newWidth,
          height: newHeight,
        },
      };
    });
  };

  useEffect(() => {
    if (resizeChat?.isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [resizeChat?.isResizing]);

  return (
    <div className='!pl-10 !h-full !flex !flex-col !gap-y-2'>
      {isAccessible ? (
        <>
          {/* updates and alerts section */}
          {allUpdatesAlerts?.length > 0 && (
            <section>
              <div
                className={classNames(
                  '!text-hexitime-primary !font-normal !outline-none !flex !items-center !text-2xl !mb-4',
                )}
              >
                <Svgs
                  icon='BellIcon'
                  fillClass='!mr-2.5 !h-7 !w-7 !flex-shrink-0 !text-hexitime-primary !fill-hexitime-primary'
                />
                <p className='!text-lg'>Updates</p>
              </div>

              <UpdatesAlerts allUpdatesAlerts={allUpdatesAlerts} setAllUpdatesAlerts={setAllUpdatesAlerts} />
            </section>
          )}

          {/* conversations section */}
          <section>
            <PrimaryButton
              btnText='Create Group'
              className='w-fit !text-lg !font-semibold hover:!text-hexitime-secondary'
              btnBGColor='secondary'
              Icon={PeopleGroup}
              isIconComponent={true}
              onClick={handleClick}
            />
          </section>
          <section className='mt-4'>
            <SmallTabs tabs={tabs} setTabs={setTabs} />
          </section>
          <section className='!relative' ref={wrapperRef}>
            <div
              className={classNames(
                '!text-hexitime-primary !font-normal !outline-none !flex !items-center !text-2xl xl:!mt-3 !mb-3',
              )}
            >
              <Svgs icon='UsersIcon' fillClass='!fill-hexitime-primary !mr-3 !h-7 !w-7 !flex-shrink-0 ' />
              <p className='!text-lg'>
                Conversations (
                {currectTabValue() == 'conversations' && allConversations?.filter((item) => item?.isShow)?.length}
                {currectTabValue() != 'conversations' &&
                  (allGroupConversations?.filter((item) => item?.groupType == currectTabValue())?.length || 0)}
                )
              </p>
            </div>
            <ChatUserListing
              shuffledColors={shuffledColors}
              loader={loader}
              allConversations={allConversations}
              showChat={showChat}
              allGroupConversations={allGroupConversations}
              desktop={true}
              clickGroupChat={clickGroupChat}
              clickUserChat={clickUserChat}
              tabs={tabs}
            />
            <div
              style={{ width: `${resizeChat?.size?.width}px`, height: `${resizeChat?.size?.height}px` }}
              className={`1xl:h-[calc(100vh-435px)] h-[calc(100vh-335px)] z-50 fixed bottom-2 right-2 ${
                showChat ? 'block' : 'hidden'
              } bg-white shadow-md border rounded-lg`}
            >
              <div
                className='absolute top-[-5px] left-[-5px] cursor-nwse-resize w-fit bg-hexitime-primary p-0.5 rounded-full border-2 border-white'
                onMouseDown={handleMouseDown}
              >
                <svg className='w-3 fill-white' viewBox='0 0 56 56' xmlns='http://www.w3.org/2000/svg'>
                  <path d='m7.8436 24.6016c1.2188 0 2.1328-.9375 2.1328-2.1797v-1.9688l-.4921-8.1797 6.1875 6.4922 7.1953 7.2656c.3984.4219.914.6094 1.5.6094 1.3125 0 2.3203-.8672 2.3203-2.1797 0-.6328-.2344-1.1953-.6563-1.6172l-7.2187-7.2187-6.5156-6.1641 8.2031.4688h1.9687c1.2422 0 2.2032-.8672 2.2032-2.1329 0-1.2656-.961-2.1562-2.2032-2.1562h-13.0312c-2.3906 0-3.7734 1.3828-3.7734 3.7735v13.0078c0 1.2187.914 2.1797 2.1796 2.1797zm25.6875 25.7578h13.0313c2.3908 0 3.7736-1.3828 3.7736-3.7735v-13.0078c0-1.2187-.9144-2.1797-2.1796-2.1797-1.219 0-2.1096.9375-2.1096 2.1797v1.9688l.4687 8.1797-6.1875-6.4922-7.1953-7.2656c-.3984-.4219-.9141-.6094-1.5-.6094-1.3125 0-2.2969.8672-2.2969 2.1796 0 .6329.211 1.1954.6328 1.6173l7.2422 7.2187 6.4922 6.1641-8.2031-.4688h-1.9688c-1.2422 0-2.1796.8672-2.2031 2.1328 0 1.2656.9609 2.1563 2.2031 2.1563z' />
                </svg>
              </div>

              <div className='flex space-x-4 h-full'>
                <div className='h-full flex flex-col border shadow-md bg-white rounded-t-lg w-full'>
                  <div className='flex items-center justify-between border-b rounded-t-lg 1xl:p-2 !p-1.5 bg-hexitime-primary'>
                    {loading ? (
                      <div className='animate-pulse flex w-3/4 items-center'>
                        <div className='rounded-full bg-hexitime-textColor4 h-9 w-9'></div>
                        <div className='flex-1 pl-4 py-1'>
                          <div className='h-4 bg-hexitime-textColor4 rounded'></div>
                        </div>
                      </div>
                    ) : !isGroup.current ? (
                      <Link
                        to={`/profile/${chatUser?.uid}`}
                        className={`flex items-center ${chatWithUser?.is_deleted_user && 'cursor-not-allowed'}`}
                        onClick={(event) => {
                          !chatWithUser?.is_deleted_user ? handleCloseChat() : event.preventDefault();
                        }}
                      >
                        {allConversations?.find((user) => user?.id === chatUser?.id)?.profilePicture ? (
                          <img
                            alt='user-profile'
                            className='rounded-full w-9 h-9 object-cover object-center'
                            src={allConversations?.find((user) => user?.id === chatUser?.id)?.profilePicture}
                          />
                        ) : (
                          <div
                            className='self-center flex justify-center items-center w-9 h-9 rounded-full uppercase'
                            style={{
                              background: shuffledColors?.bgColor,
                            }}
                          >
                            <span
                              style={{
                                color: shuffledColors?.textColor,
                              }}
                            >
                              {chatUser?.name?.charAt(0)}
                            </span>
                            <span
                              style={{
                                color: shuffledColors?.textColor,
                              }}
                            >
                              {chatUser?.name?.split(' ')[1] && chatUser?.name?.split(' ')[1]?.charAt(0)}
                            </span>
                          </div>
                        )}
                        <div className='pl-4'>
                          <p
                            className={`${
                              !chatWithUser?.is_deleted_user && 'hover:underline'
                            } text-white font-medium text-base w-fit cursor-pointer hover:text-slate-300`}
                          >
                            {truncateDescription(chatUser?.name, 0.187 * resizeChat?.size?.width - 32.75)}
                          </p>
                        </div>
                      </Link>
                    ) : (
                      <div className='flex justify-center items-center gap-x-3'>
                        {allGroupConversations?.[currentChatIndex]?.groupImage &&
                        allGroupConversations?.[currentChatIndex]?.groupImage != '' ? (
                          <img
                            className={`h-9 w-9 rounded-full object-cover object-center cursor-pointer`}
                            src={allGroupConversations?.[currentChatIndex]?.groupImage}
                            alt='user-profile'
                          />
                        ) : (
                          <Svgs icon='peopleGroup' fillClass='rounded-full w-9 w-[40px] h-[37px]' fill='white'></Svgs>
                        )}

                        <p
                          className={`text-white font-medium text-base w-fit cursor-pointer hover:text-slate-300`}
                          onClick={handleShowMember}
                        >
                          {truncateDescription(
                            allGroupConversations?.[currentChatIndex]?.name,
                            0.187 * resizeChat?.size?.width - 25.75,
                          )}
                        </p>
                      </div>
                    )}

                    <div>
                      <button
                        className='inline-flex hover:bg-hexitime-primary rounded-full p-2'
                        type='button'
                        onClick={() => handleCloseChat()}
                      >
                        <XMarkIcon
                          className='h-5 w-5 text-white hover:text-hexitime-textColor4 rounded-full'
                          aria-hidden='true'
                        />
                        <span className='sr-only'>Close chat popup</span>
                      </button>
                    </div>
                  </div>

                  <div
                    className={`flex-1 px-4 mt-4 overflow-y-auto overscroll-y-contain w-[calc(100%-7px)] relative ${
                      isScrollBottom ? '' : ''
                    }`}
                    ref={chatContainerRef}
                  >
                    {chatLoader ? (
                      <div className='flex justify-center items-center h-full'>
                        <ButtonSpinner />
                      </div>
                    ) : (
                      <>
                        {nextPostsLoading && <ButtonSpinner />}
                        {chatWithDate?.map((group, index) => (
                          <div key={index}>
                            <p
                              className={`date bg-white text-hexitime-textColor2 1xl:py-2 py-1 px-4 rounded-full text-center text-sm ${
                                isGroup.current && 'mb-1'
                              }`}
                            >
                              {group?.date}
                            </p>
                            {group?.messages.map((message, i) => (
                              <div
                                className={`flex items-start mb-2 ${
                                  message?.senderId === userD?.user_id ? 'flex-row-reverse' : ''
                                } message`}
                                key={i}
                                data-date={group.date}
                              >
                                <div className='leading-[1]'>
                                  <div
                                    className={`flex gap-2 items-start text-left ${
                                      message?.senderId === userD?.user_id && 'text-white flex-row-reverse '
                                    } relative`}
                                  >
                                    {isGroup.current || message?.type === 'text' ? (
                                      <>
                                        {isGroup.current ? (
                                          message.senderProfile ? (
                                            <img
                                              className='h-7 w-7 place-self-start flex-none rounded-full bg-white object-cover object-center'
                                              src={message?.senderProfile}
                                              alt='profile'
                                            />
                                          ) : (
                                            <DefaultProfileImage
                                              first_name={message.senderName?.split(' ')[0]}
                                              last_name={message.senderName?.split(' ')[1]}
                                              classValue={
                                                'h-7 w-7 !self-start flex-none rounded-full bg-white object-cover object-center text-xs'
                                              }
                                            />
                                          )
                                        ) : (
                                          ''
                                        )}
                                        <div
                                          className={`space-y-1 ${message.senderId === userD.user_id && 'text-right'}`}
                                        >
                                          {isGroup.current && (
                                            <span className='text-xs text-hexitime-textColor2 whitespace-nowrap'>
                                              {message.senderId === userD.user_id ? 'You' : message.senderName}
                                            </span>
                                          )}
                                          <div className='flex flex-col w-fit'>
                                            <p
                                              className={`${
                                                message?.senderId === userD?.user_id
                                                  ? 'custom-anchor !bg-hexitime-primary text-left'
                                                  : 'text-hexitime-textColor1'
                                              } text-sm custom-anchor-underline custom-text-break bg-hexitime-textColor5 px-3 py-1.5 rounded-md whitespace-pre-wrap max-w-[250px] ${
                                                isGroup.current && ''
                                              }`}
                                              style={{ maxWidth: `${Math.max(resizeChat?.size?.width - 200, 250)}px` }}
                                              dangerouslySetInnerHTML={{
                                                __html: message?.text,
                                              }}
                                            />
                                            <span className='text-[10px] text-gray-500 leading-none self-end mt-1'>
                                              {formatTime(message?.createdAt)}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className='flex flex-col gap-1'>
                                        <p
                                          className={`${
                                            message?.senderId === userD?.user_id
                                              ? 'custom-anchor !bg-hexitime-primary text-left'
                                              : 'text-hexitime-textColor1'
                                          } text-sm custom-anchor-underline custom-text-break bg-hexitime-textColor5 px-3 py-1.5 rounded-md whitespace-pre-wrap max-w-[250px]`}
                                          style={{ maxWidth: `${Math.max(resizeChat?.size?.width - 200, 250)}px` }}
                                          dangerouslySetInnerHTML={{
                                            __html: message?.text,
                                          }}
                                          onClick={(event) => {
                                            const target = event.target;
                                            if (target?.tagName.toLowerCase() === 'a') {
                                              handleLinkClick(event, target?.href);
                                            }
                                          }}
                                        />
                                        <span className='text-[10px] text-gray-500 leading-none self-end'>
                                          {formatTime(message?.createdAt)}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  {(isGroup.current || !chatWithUser?.is_deleted_user) && (
                    <div className={`flex items-center p-2 pt-0 mb-2`}>
                      <div className='w-full mx-2'>
                        <WriteMessage
                          chatUser={chatUser}
                          chatLoader={chatLoader}
                          currentUserDocId={userFirebaseId}
                          allConversations={allConversations}
                          sortConversations={sortConversations}
                          message={message}
                          setMessage={setMessage}
                          groupChatIdIfAny={isGroup.current ? currentChatId : ''}
                        />
                      </div>
                    </div>
                  )}
                  {isScrollBottom && !chatLoader && (
                    <div
                      className='rounded-full cursor-pointer border shadow-md shadow-hexitime-textColor4 absolute left-1/2 bg-white bottom-16 p-1 -translate-x-1/2 -translate-y-1/2 z-20'
                      onClick={scrollToBottom}
                    >
                      <ArrowDownIcon className='h-4 w-4 text-hexitime-textColor2' />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          {isGroupChatCreateModalOpen && (
            <ChatGroupCreateModal
              open={isGroupChatCreateModalOpen}
              setOpen={setGroupChatCreateModalOpen}
              groupData={GroupEdit}
            />
          )}
          {isShowGroupMembers && (
            <ShowGroupMemberModal
              open={isShowGroupMembers}
              setOpen={setShowGroupMembers}
              groupId={currentChatId}
              handleGroupEdit={handleGroupEdit}
              groupName={allGroupConversations?.[currentChatIndex]?.name}
              handleCloseChat={handleCloseChat}
            />
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default RightSidebar;
