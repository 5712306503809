/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { successToast } from '../../utils/helper';
import { Api } from '../../api';
import { XCircleIcon } from '@heroicons/react/20/solid';
import TagListing from './TagListing';
import { DefaultProfileImage } from './DefaultProfileImage';
import Svgs from './Svgs';

const SearchableSelect = ({
  setSelectedList,
  label,
  error,
  data,
  selectedList,
  searchTerm,
  setSearchTerm,
  apiKey,
  handleListing,
  setRemovedList,
  isDisabled = false,
  showTop = false,
  isRequired,
  showProfile,
  totalUser,
  setPage,
  setUsersList,
  isLoading,
}) => {
  const wrapperRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setError] = useState('');
  const [dropDownData, setDropDownData] = useState([]);
  const [arr, setArr] = useState(selectedList || []);

  useEffect(() => {
    setError(error);
  }, [error]);

  useEffect(() => {
    if (selectedList?.length >= 0) {
      setError('');
    }
  }, [selectedList]);

  const handleAPICall = () => {
    setLoader(false);

    let temp = [];

    data?.map((e) => {
      temp.push({
        id: e?.id,
        name: e?.name,
        checked: selectedList?.some((a) => a?.id === e?.id),
        profile: e.profile,
      });
      return null;
    });

    setDropDownData(temp);
  };

  useEffect(() => {
    setArr(selectedList);
    handleAPICall();
    // eslint-disable-next-line
  }, [selectedList]);

  useEffect(() => {
    setLoader(true);

    if (data) {
      handleAPICall();
    } else {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    setLoader(true);

    if (searchTerm || searchTerm === '') {
      const delayDebounceFn = setTimeout(() => {
        // if the total props is included that means the component called for chat user listing with pagination
        totalUser && setUsersList([]);
        handleListing();
      }, 600);
      totalUser && setPage(1);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [searchTerm]);

  const handleChange = (e) => {
    e.preventDefault();

    const { value } = e.target;

    const regex = /^[A-Za-z0-9 ]*$/;

    if (value?.length === 0) {
      setSearchTerm(value);
    } else if (value?.charAt(0) !== ' ' && regex.test(value)) {
      setSearchTerm(value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (searchTerm || searchTerm !== '') {
        if (apiKey === 'skill') {
          const payload = {
            skill_name: searchTerm,
          };

          Api.createSkill(payload)
            .then((response) => {
              if (response?.data?.meta?.code === 200) {
                setArr((prevState) => [
                  ...prevState,
                  {
                    name: response?.data?.detail?.name,
                    id: response?.data?.detail?.id,
                    checked: true,
                  },
                ]);

                setSelectedList((prevState) => [
                  ...prevState,
                  {
                    name: response?.data?.detail?.name,
                    id: response?.data?.detail?.id,
                    checked: true,
                  },
                ]);
                successToast(response?.data?.meta?.message);
                handleListing();
              }
            })
            .finally(() => {
              setLoader(false);
            });
        }

        if (apiKey === 'interest') {
          const payload = {
            interest_name: searchTerm,
          };

          Api.createInterest(payload)
            .then((response) => {
              if (response?.data?.meta?.code === 200) {
                successToast(response?.data?.meta?.message);
                setArr((prevState) => [
                  ...prevState,
                  {
                    name: response?.data?.detail?.name,
                    id: response?.data?.detail?.id,
                    checked: true,
                  },
                ]);
                setSelectedList((prevState) => [
                  ...prevState,
                  {
                    name: response?.data?.detail?.name,
                    id: response?.data?.detail?.id,
                    checked: true,
                  },
                ]);
                handleListing();
              }
            })
            .finally(() => {
              setLoader(false);
            });
        }
      }
    }
  };

  const handleCheckbox = (e, item) => {
    const shouldRenderButton = (apiKey === 'network' && item?.id != 1) || apiKey !== 'network';

    if (!isDisabled && shouldRenderButton) {
      if (e?.target?.checked) {
        dropDownData?.map((data) => {
          if (data.id === item.id) {
            let tempArr = [{ name: item.name, id: item?.id, checked: true }];
            let filterData = dropDownData.map((obj) => tempArr.find((o) => o.id === obj.id) || obj);
            setDropDownData(filterData);
          }
          return null;
        });
        setArr((prevState) => [...prevState, { name: item.name, id: item?.id, checked: true }]);

        setSelectedList((prevState) => [...prevState, { name: item.name, id: item?.id, checked: true }]);

        setRemovedList((prevList) => prevList.filter((i) => item.id !== i.id));
      } else {
        dropDownData?.map((data) => {
          if (data.id === item.id) {
            const tempObj = { name: item.name, id: item?.id, checked: false };

            setRemovedList((prev) => [...prev, tempObj]);

            let tempArr = [{ name: item.name, id: item?.id, checked: false }];
            let filterData = dropDownData.map((obj) => tempArr.find((o) => o.id === obj.id) || obj);
            setDropDownData(filterData);
          }
          return null;
        });
        const filterData = selectedList.filter((data) => data.id !== item.id);
        setArr(filterData);
        setSelectedList(filterData);
      }
    }
  };

  const deleteCapsule = (item) => {
    if (!isDisabled) {
      const tempObj = { name: item.name, id: item?.id, checked: false };
      setRemovedList((prev) => [...prev, tempObj]);

      let tempArr = [{ name: item.name, id: item?.id, checked: false }];

      let filterData = dropDownData?.map((obj) => tempArr?.find((o) => o.id === obj.id) || obj);

      setDropDownData(filterData);
      const filterArrData = arr.filter((data) => data.id !== item.id);
      setArr(filterArrData);

      setSelectedList(filterArrData);
    }
  };

  const handleClick = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      if (openDropdown) setOpenDropdown(!openDropdown);
      setSearchTerm('');
      handleAPICall();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleLoadMore = () => {
    setPage((page) => page + 1);
  };
  return (
    <div>
      <div className='relative text-left' ref={wrapperRef}>
        {label && (
          <label
            htmlFor={label}
            className={`flex ${
              label ? 'justify-between' : 'justify-end'
            } text-sm xl:text-base font-medium text-hexitime-textColor1 items-center`}
          >
            <span>
              {label} {isRequired && <span className='text-hexitime-primaryRed'>&#42;</span>}
            </span>

            {searchTerm?.length > 0 && apiKey !== 'network' && !isDisabled && (
              <span className='text-sm'>Please save to create</span>
            )}
          </label>
        )}

        {!isDisabled && (
          <>
            <div className='relative mt-2'>
              <input
                type='text'
                name='searchTerm'
                id={label}
                placeholder='Type or select'
                value={searchTerm}
                onChange={(e) => {
                  handleChange(e);
                  setOpenDropdown(true);
                  if (selectedList?.length > 0) {
                    setError('');
                  }
                  setLoader(true);
                }}
                onKeyDown={(e) => handleKeyDown(e)}
                onClick={() => setOpenDropdown(true)}
                className={`px-[17px] pr-10 2xl:py-4 py-3 mt-1 block w-full rounded-lg appearance-none border ${
                  errorMessage ? 'border-hexitime-primaryRed' : 'border-hexitime-textColor4'
                } placeholder-hexitime-textColor2 focus:border-hexitime-primary focus:outline-none focus:ring-hexitime-primary sm:text-sm`}
                disabled={isDisabled}
                autoComplete='off'
              />
              {searchTerm?.length > 0 && (
                <div className='absolute inset-y-0 right-0 flex items-center pr-3 z-10'>
                  <XCircleIcon
                    className='h-5 w-5 text-hexitime-textColor3 cursor-pointer'
                    aria-hidden='true'
                    onClick={() => setSearchTerm('')}
                  />
                </div>
              )}
            </div>
            {errorMessage && (
              <span className='error text-sm xl:text-base text-hexitime-primaryRed'>{errorMessage}</span>
            )}
          </>
        )}

        <Transition
          show={openDropdown && !isDisabled}
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <div
            className={`absolute bg-white overflow-auto left-0 z-10 my-2 w-full origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
              showTop ? 'bottom-[2.8rem]' : ''
            }`}
          >
            <div className='py-1 max-h-[150px] h-auto'>
              <div>
                {dropDownData?.length > 0 &&
                  dropDownData?.map((item, index) => {
                    return (
                      <div className='relative flex items-start p-2 gap-x-2' key={index}>
                        <div className='flex h-5 items-center'>
                          <input
                            id={item.name}
                            name='comments'
                            checked={
                              apiKey === 'network' && item?.id == 1 // Prevent deselection for network having id 1
                                ? true
                                : item?.checked
                            }
                            type='checkbox'
                            onChange={(e) => handleCheckbox(e, item)}
                            className='h-4 w-4 rounded border-gray-300 cursor-pointer text-indigo-600 focus:ring-indigo-500'
                            autoComplete='off'
                          />
                        </div>
                        {showProfile &&
                          (item?.profile ? (
                            <img src={item?.profile} alt='DP' className='h-6 w-6 rounded-full' />
                          ) : (
                            <DefaultProfileImage
                              first_name={item?.name?.split(' ')[0]}
                              last_name={item?.name?.split(' ')[1]}
                              classValue={'h-6 w-6'}
                            ></DefaultProfileImage>
                          ))}
                        <div className='ml-3 text-sm'>
                          <label htmlFor={item.name} className='font-medium text-gray-700 cursor-pointer'>
                            {item.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                {!loader && !isLoading && totalUser && dropDownData?.length < totalUser && (
                  <div
                    className='text-sm text-hexitime-primary flex flex-col justify-center items-center cursor-pointer'
                    onClick={handleLoadMore}
                  >
                    <label className='font-medium text-gray-700 '>Load More</label>
                    <Svgs icon='angleDown' fillClass='h-6 w-6' />
                  </div>
                )}
                {!isLoading && !loader && dropDownData?.length === 0 && (
                  <div className='text-center py-2'>
                    <p className='m-0 text-sm'>No data</p>
                  </div>
                )}
                {(loader || isLoading) && (
                  <div className='text-center py-2'>
                    <p className='m-0 text-sm'>Loading...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Transition>
      </div>
      {arr?.length > 0 && (
        <div className='flex flex-wrap pt-1'>
          <TagListing
            list={arr}
            isRemove={true}
            deleteCapsule={deleteCapsule}
            isDisabled={isDisabled}
            tagsName={apiKey}
          />
        </div>
      )}
    </div>
  );
};

SearchableSelect.propTypes = {
  setSelectedList: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.string,
  data: PropTypes.array,
  selectedList: PropTypes.array,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  apiKey: PropTypes.string,
  handleListing: PropTypes.func,
  setRemovedList: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default SearchableSelect;
