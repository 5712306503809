import { doc, getDoc } from 'firebase/firestore';
import React, { Fragment, useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { errorToast, getRandomColor, truncateDescription, userData } from '../../../utils/helper';
import { Dialog, Transition } from '@headlessui/react';
import Loader from '../../../component/common/Loader';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from '../../../api';
import Svgs from '../../../component/common/Svgs';

export default function ShowGroupMemberModal({ open, setOpen, groupId, groupName, handleGroupEdit, handleCloseChat }) {
  const [fullLoader, setFullLoader] = useState(false);
  const [members, setMembers] = useState([]);
  const [groupAdminIndexInMemberList, setGroupAdminIndexInMemberList] = useState('');
  const [groupProfileImage, setGroupProfileImage] = useState('');
  const shuffledColors = getRandomColor();
  const history = useHistory();
  const fetchGroupMemberByGroupId = async (groupId) => {
    try {
      if (groupId) {
        const docRef = doc(db, 'groups', groupId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          const groupData = docSnapshot.data();
          if (groupData?.members) {
            const userDetail = await Api.getChatUsersData({
              users_id_list: groupData?.members?.map((member) => member.id),
            });
            setGroupProfileImage(groupData?.groupImage);
            setMembers(
              groupData.members?.map((member, index) => {
                if (member.id == groupData?.createdBy) {
                  setGroupAdminIndexInMemberList(index);
                }
                return {
                  ...member,
                  profile_picture: userDetail?.data?.detail?.find((detail) => detail.user_id == member.id)
                    ?.profile_picture,
                };
              }),
            );
          } else {
            errorToast('Group members not found');
          }
        } else {
          errorToast('Group info not exists');
        }
        setFullLoader(false);
      }
    } catch (error) {
      errorToast('Error while loading group members');
    }
  };
  useEffect(() => {
    setFullLoader(true);
    fetchGroupMemberByGroupId(groupId);
  }, [groupId]);

  const handleClick = (id) => {
    history.push(`/profile/${id}`);
    handleCloseChat();
    setOpen(false);
  };

  const isGroupAdmin = () => {
    if (members?.[groupAdminIndexInMemberList]?.id == userData()?.user_id) {
      return true;
    }
    return false;
  };

  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-hidden'>
          <div className='flex min-h-full justify-center !p-4 text-center items-center sm:!p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform !overflow-hidden !rounded-lg bg-white !px-4 !pb-4 !pt-5 text-left shadow-xl transition-all sm:!mt-12 w-full sm:max-w-lg sm:!p-6'>
                {fullLoader ? (
                  <Loader />
                ) : (
                  <>
                    <div className='flex items-start justify-between !pb-2 border-b rounded-t dark:border-gray-600'>
                      <Dialog.Title
                        as='h3'
                        className='text-xl font-semibold text-gray-900 flex gap-2 justify-center items-center'
                      >
                        {groupProfileImage && groupProfileImage !== '' ? (
                          <img
                            className={`h-9 w-9 rounded-full object-cover object-center cursor-pointer`}
                            src={groupProfileImage}
                            alt='user-profile'
                          />
                        ) : (
                          <Svgs icon='peopleGroup' fillClass='rounded-full w-9 h-9 object-cover'></Svgs>
                        )}
                        {`${truncateDescription(groupName, 29)} Members`}
                      </Dialog.Title>

                      <div className='flex items-center'>
                        {isGroupAdmin() && (
                          <button
                            type='button'
                            className='text-hexitime-textColor2 bg-hexitime-textColor1 mr-3 rounded-full text-sm !p-1.5 ml-auto inline-flex items-center outline-none'
                            data-modal-hide='defaultModal'
                            onClick={() => {
                              handleGroupEdit({ groupId, groupName, members, groupImage: groupProfileImage });
                            }}
                          >
                            <p className='ml-2 text-white mr-2'>Edit</p>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='h-4 w-4 text-white'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125'
                              />
                            </svg>
                            <span className='sr-only'>Edit modal</span>
                          </button>
                        )}
                        <button
                          type='button'
                          className='text-hexitime-textColor2 bg-hexitime-textColor1 rounded-full text-sm !p-1.5 ml-auto inline-flex items-center outline-none'
                          data-modal-hide='defaultModal'
                          onClick={() => setOpen(false)}
                        >
                          <XMarkIcon className='h-4 w-4 text-white' aria-hidden='true' />
                          <span className='sr-only'>Close modal</span>
                        </button>
                      </div>
                    </div>
                    <div className='!mt-4'>
                      <ul className='text-left overflow-auto !max-h-[300px] !pl-0 !mt-6'>
                        {members.length > 0
                          ? members?.map((member, i) => (
                              <li key={i} className='flex justify-between !py-1'>
                                <div className='flex gap-x-2'>
                                  <Link
                                    to={`#`}
                                    onClick={() => handleClick(member?.id)}
                                    className='!max-w-[48px] w-full'
                                  >
                                    {member?.profile_picture ? (
                                      <img
                                        className='!h-12 !w-12 flex-none rounded-full bg-white object-cover object-center'
                                        src={member?.profile_picture}
                                        alt='profile'
                                      />
                                    ) : (
                                      <div
                                        className='self-center flex justify-center items-center !w-12 !h-12 rounded-full'
                                        style={{
                                          background: shuffledColors?.bgColor,
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: shuffledColors?.textColor,
                                          }}
                                        >
                                          {member?.name?.split(' ')?.[0]?.toUpperCase()?.charAt(0)}
                                        </span>
                                        <span
                                          style={{
                                            color: shuffledColors?.textColor,
                                          }}
                                        >
                                          {member?.name?.split(' ')?.[1]?.toUpperCase().charAt(0)}
                                        </span>
                                      </div>
                                    )}
                                  </Link>

                                  <div className='items-start flex justify-center flex-col w-full !pr-1'>
                                    <p className='!text-base !font-semibold leading-none !text-hexitime-textColor1 whitespace-pre-wrap'>
                                      <Link to={`#`} onClick={() => handleClick(member?.id)} className='w-full'>
                                        {member?.name}
                                        {i === groupAdminIndexInMemberList && (
                                          <span className='!ml-1 !text-xs !font-semibold !leading-none !text-hexitime-textColor1'>
                                            (Admin)
                                          </span>
                                        )}
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))
                          : ''}
                      </ul>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
