import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as SendMessageIcon } from '../../assets/images/send-message.svg';
import { UserDetailsContext, userData } from '../../utils/helper';
import ButtonSpinner from '../../component/common/Buttons/ButtonSpinner';

import { db } from '../../firebase';
import '../../';
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';

const WriteMessage = ({
  chatUser,
  chatLoader,
  allConversations,
  currentUserDocId,
  sortConversations,
  message,
  setMessage,
  groupChatIdIfAny,
}) => {
  const { userFirebaseId, profilePicture } = useContext(UserDetailsContext);
  const inputRef = useRef(null);
  const [error, setError] = useState('');
  // eslint-disable-next-line
  const [isCommentLoader, setIsCommentLoader] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [inputHeight, setInputHeight] = useState(28);

  const userD = userData();

  const handleChange = (e) => {
    setMessage(e.target.value);
    setError('');

    if (e?.target?.value.length > 0) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  };

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';

      if (inputHeight < 65) {
        setInputHeight(inputRef.current.scrollHeight);
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
      }
    }
  };

  useEffect(() => {
    if (message === '') {
      setInputHeight(28);
    }
    adjustTextareaHeight();
    // eslint-disable-next-line
  }, [message]);

  const keyDown = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      handleMessageSubmit(e);
    }
  };

  const fetchData = async () => {
    if (groupChatIdIfAny) {
      const docRef = doc(db, 'groups', groupChatIdIfAny);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && isUpdate) {
        const data = docSnap.data();
        const userRef = collection(db, 'users');
        const q = query(
          userRef,
          where('uid', 'in', [
            ...data.members.filter((member) => member.id !== userD?.user_id).map((user) => user.id?.toString()),
          ]),
        );
        const userQuerySnapshot = await getDocs(q);
        const updateGroupPromises = userQuerySnapshot.docs.map(async (docSnapshot) => {
          const userRef1 = docSnapshot.ref;
          const groups = docSnapshot.data().groups;
          const targetGroup = groups?.findIndex((group) => group?.id === groupChatIdIfAny);
          groups[targetGroup] = {
            ...groups[targetGroup],
            unreadMessageCount: groups[targetGroup].unreadMessageCount + 1,
            updatedAt: Timestamp.fromDate(new Date()),
          };
          return updateDoc(userRef1, {
            groups: groups,
          });
        });
        await Promise.all(updateGroupPromises);
        sortConversations();
        setIsUpdate(false);
      }
    } else {
      const docRef = doc(db, 'users', chatUser?.id);

      const docSnap = await getDoc(docRef);

      const tempConversation = docSnap.data();

      const updatedConversations = tempConversation?.conversations?.map((conversation) => {
        if (conversation?.uid === userD?.user_id) {
          return {
            ...conversation,
            updatedAt: Timestamp.fromDate(new Date()),
            unread: parseInt(conversation?.unread) + 1,
            isShow: true,
          };
        }
        return conversation;
      });

      const userUpdateConversation = allConversations?.map((conversation) => {
        if (conversation?.uid === chatUser?.uid) {
          return {
            ...conversation,
            updatedAt: Timestamp.fromDate(new Date()),
            isShow: true,
          };
        }
        return conversation;
      });

      if (isUpdate) {
        await updateDoc(doc(db, 'users', chatUser?.id), {
          conversations: updatedConversations,
        });

        if (currentUserDocId) {
          await updateDoc(doc(db, 'users', currentUserDocId), {
            conversations: userUpdateConversation,
          });

          sortConversations(userUpdateConversation);
        }

        setIsUpdate(false);
      }
    }
  };

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    if (message.trim() === '') {
      return;
    }
    let chatId = groupChatIdIfAny;
    if (!groupChatIdIfAny) {
      let v = [userFirebaseId, chatUser?.id];
      chatId = v.sort().join('_');
    }
    addUser(chatId);
  };

  const addUser = async (chatId) => {
    setMessage('');
    let newCollectionRef = collection(db, 'messages', chatId, 'conversations');
    if (groupChatIdIfAny) {
      newCollectionRef = collection(db, 'groups', chatId, 'conversations');
    }
    let urlMessage = '';

    const urlRegex = /(http[s]?:\/\/[^\s]+)/g;
    const matches = message?.match(urlRegex);

    if (matches) {
      let formattedMessage = message;
      matches?.forEach((match) => {
        formattedMessage = formattedMessage?.replace(
          match,
          `<a href="${match}" className="underline" target="_blank" rel="noopener noreferrer">${match}</a>`,
        );
      });
      urlMessage = formattedMessage;
    } else {
      urlMessage = message;
    }

    let messagePayload = {
      createdAt: Date.now(),
      senderName: userD?.user_name,
      text: urlMessage?.trim(),
      senderId: userD?.user_id,
      avatar: profilePicture,
      type: 'text',
    };
    if (groupChatIdIfAny) {
      delete messagePayload.avatar;
      messagePayload['senderProfile'] = profilePicture;
      messagePayload['updatedAt'] = Date.now();
    }

    await addDoc(newCollectionRef, messagePayload);
    !groupChatIdIfAny &&
      (await updateDoc(doc(db, 'messages', chatId), {
        chatId: chatId,
      }));
    await fetchData();
  };

  useEffect(() => {
    if (inputRef.current && !chatLoader) {
      inputRef.current.focus();
    }
  }, [chatLoader]);

  return (
    <>
      <section className='mt-4'>
        <form onSubmit={(e) => handleMessageSubmit(e)}>
          <div
            className={`relative mt-2 rounded-md shadow-sm flex border border-hexitime-textColor4 px-3 py-1.5 bg-white items-center min-h-[50px] focus-within:border-hexitime-primary ${
              isCommentLoader ? 'opacity-50' : ''
            } pr-1 max-h-[300px] overflow-auto`}
          >
            <textarea
              type='text'
              name='account-number'
              ref={inputRef}
              id='account-number'
              className='scroll  p-2.5  resize-none block max-h-[90px] rounded-r-none w-full appearance-none placeholder-hexitime-textColor2 pr-8 placeholder:text-base focus:border-hexitime-primary focus:outline-none focus:ring-hexitime-primary text-base bg-white h-full font-light !min-h-[24px]'
              placeholder='Write your message'
              onKeyDown={keyDown}
              value={message}
              onChange={(e) => handleChange(e)}
              disabled={isCommentLoader}
              autoFocus
              rows='1'
              style={{
                minHeight: inputHeight < 28 ? 28 : inputHeight,
              }}
            />

            {!isCommentLoader ? (
              <div
                className='rounded-full bg-hexitime-primary flex items-center justify-center p-2 cursor-pointer absolute right-[10px] bottom-[10px]'
                onClick={(e) => handleMessageSubmit(e)}
              >
                <SendMessageIcon className='h-4 w-4' />
              </div>
            ) : (
              <ButtonSpinner />
            )}
          </div>
        </form>
        {error && <span className='error text-sm xl:text-base text-hexitime-primaryRed leading-[1px]'>{error}</span>}
      </section>
    </>
  );
};

export default WriteMessage;
