import React from 'react';
import '../../assets/css/timebank.css';
import WWFooter from './WwFooter';
export default function TimeBank() {
  return (
    <div className='col-xs-12 col-sm-8 col-lg-9 snipcss-6mETK mt-10 m-auto'>
      <h2 className='footer-h2'>Timebanking Terms and Conditions</h2>
      <div className='page-content contains_vid'>
        <p className='footer-p'>
          These additional terms complement our general terms, ensuring a secure and beneficial environment for all
          timebank members. Changes may occur, so staying informed is vital.
        </p>
        <p className='footer-p'>
          <strong>Conduct and Care</strong>
        </p>
        <p className='footer-p'>
          Joining means you commit to valuing member privacy, differing opinions, and maintaining a non-monetary
          exchange ethos. Personal space, property respect, and adhering to a smoke-free environment during exchanges
          are key.
        </p>

        <p className='footer-p'>
          <strong>Exchange Guidance</strong>
        </p>
        <ul className='footer-ul !ml-[8px]'>
          <li>Expenses: Members handle their own without a formal reimbursement process.</li>
          <li>Cancellations: Courtesy dictates giving ample notice for any changes.</li>
          <li>Safety First: Prioritise personal and others' safety always. If uncertain, opt-out.</li>
          <li>Instincts Matter: Trust your feelings for safety and comfort during exchanges.</li>
        </ul>
        <p className='footer-p'>
          <strong>Member Duties and Rights</strong>
        </p>
        <p className='footer-p'>
          Your participation means assuming responsibility for your interactions, understanding the risks, and
          acknowledging that the timebank’s oversight is limited. Your actions within the timebank are your own, yet
          you're part of a community with shared expectations and support.
        </p>
        <p>Giver's Rights:</p>
        <ul className='footer-ul !ml-[6px]'>
          <li>
            Earn credits per hour of service, with the freedom to bank, use, or donate these credits within the timebank
            community.
          </li>
        </ul>
        <p>Receiver's Rights:</p>
        <ul className='footer-ul !ml-[6px]'>
          <li>Respectful treatment and equitable service exchange rates are standard.</li>
        </ul>
        <p>Giver's Responsibilities:</p>
        <ul className='footer-ul !ml-[6px]'>
          <li>Offer services within your capability, legally compliant, and with any necessary certifications.</li>
        </ul>
        <p>Receiver's Responsibilities:</p>
        <ul className='footer-ul !ml-[6px]'>
          <li>It's your duty to ensure a service provider's suitability, legality, and safety.</li>
        </ul>
        <p className='footer-p'>
          <strong>Addressing Concerns</strong>
        </p>
        <p className='footer-p'>
          Any service issues should be directed to the timebank coordinator at&nbsp;
          <a href='mailto:innovate@warwick.ac.uk' className='underline'>
            innovate@warwick.ac.uk
          </a>
          &nbsp;who may refer unresolved matters to the licensing organisation, For more information about these
          Timebanking Terms and Conditions, contact Hexitime at &nbsp;
          <a className='underline' href='mailto:hello@hexitime.com'>
            hello@hexitime.com
          </a>
        </p>

        <p className='footer-p'>
          <strong>Disclaimers</strong>
        </p>
        <p className='footer-p'>The platform's coordinators aren't liable for:</p>
        <ul className='footer-ul !ml-[6px]'>
          <li>
            The reliability, truthfulness, relevance, or completeness of content shared by members on the site,
            including what you may encounter while browsing.
          </li>
          <li>The behaviour of members, whether online or in person. </li>
          <li>Removing or deleting any of your contributions or ads.</li>
          <li>Mistakes in site content or the management and redemption of time credits.</li>
          <li>Delays, pauses, or the cessation of the website and its services, affecting your account.</li>
          <li>
            Guaranteeing the site is free of errors, defects, interruptions, cyber threats, or unauthorised access.
          </li>
          <li>The advertised services by members, including their quality, nature, or appropriateness.</li>
        </ul>

        <p className='footer-p'>
          <strong>Liability</strong>
        </p>
        <p className='footer-p'>
          Participation in timebanking activities is at your own risk, with the platform coordinator disclaiming
          liability for any related losses or damages.
        </p>
        <p className='footer-p'>
          <strong>Indemnification</strong>
        </p>
        <p className='footer-p'>
          You agree to protect the platform coordinator against losses incurred through your timebanking participation.
        </p>
        <p className='footer-p'>
          <strong>Tax Responsibilities</strong>
        </p>
        <p className='footer-p'>
          You’re accountable for understanding and fulfilling any tax obligations arising from timebank services.
        </p>
        <p className='footer-p'>
          <strong>Resolving Disputes</strong>
        </p>
        <p className='footer-p'>
          Members are encouraged to settle differences among themselves, with unresolved issues potentially mediated by
          the platform coordinator.
        </p>
        <p className='footer-p'>
          <strong>General Terms</strong>
        </p>
        <p className='footer-p'>
          These terms do not establish any form of employment or legal partnership among members or with the platform.
          Governed by the laws of England and Wales, they outline the framework for respectful and productive
          timebanking.
        </p>
      </div>
      <WWFooter />
    </div>
  );
}
