import React from 'react';
import Svgs from '../../component/common/Svgs';
import Loader from '../../component/common/Loader';

const ChatUserListing = ({
  shuffledColors,
  allConversations,
  showChat,
  allGroupConversations,
  loader,
  clickGroupChat,
  desktop,
  clickUserChat,
  tabs,
}) => {
  const currectTabValue = () => {
    return tabs?.find((tab) => tab.current).value;
  };
  return (
    <ul
      className={` ${
        desktop
          ? '!overflow-y-auto 1xl:!max-h-[calc(100vh-460px)] !max-h-[calc(100vh-362px)]'
          : 'w-full bg-white rounded-lg px-3 py-2'
      } ${!showChat ? 'block' : desktop ? '' : 'hidden'}`}
    >
      {desktop
        ? loader && <Loader />
        : (!allConversations || allConversations?.length === 0) &&
          (!allGroupConversations || allGroupConversations.length === 0) && (
            <p className='text-center'>Oops.., you haven't chat with anyone yet</p>
          )}
      {currectTabValue() == 'conversations' &&
        allConversations?.map(
          (person, i) =>
            person?.isShow && (
              <li
                key={i}
                className={`flex gap-x-4 items-center  ${
                  desktop ? '!py-1.5 !items-center' : 'py-2 border-b last:border-0'
                } `}
              >
                <span className='relative inline-block'>
                  {person?.profilePicture && person?.profilePicture !== 'null' ? (
                    <img
                      className={`h-9 min-w-9 rounded-full object-cover object-center ${desktop && 'cursor-pointer'}`}
                      src={person?.profilePicture}
                      alt='user-profile'
                    />
                  ) : (
                    <div
                      className='self-center flex justify-center items-center w-9 h-9 rounded-full uppercase'
                      style={{
                        background: shuffledColors?.bgColor,
                      }}
                    >
                      <span
                        style={{
                          color: shuffledColors?.textColor,
                        }}
                      >
                        {person?.name?.charAt(0)}
                      </span>
                      <span
                        style={{
                          color: shuffledColors?.textColor,
                        }}
                      >
                        {person?.name?.split(' ')[1] && person?.name?.split(' ')[1]?.charAt(0)}
                      </span>
                    </div>
                  )}
                  {/* <span className="absolute right-0 top-0 block h-1.5 w-1.5 rounded-full bg-hexitime-secondary ring-2 ring-white" /> */}
                </span>
                <div className='min-w-0 flex items-center gap-x-4 cursor-pointer' onClick={() => clickUserChat(person)}>
                  <p className='text-sm font-semibold leading-6 text-gray-900 break-all'>{person?.name}</p>

                  {parseInt(person?.unread) > 0 && (
                    <p className='rounded-full h-6 min-w-6 bg-hexitime-primaryRed p-1 text-[0.75rem] text-white shadow-sm focus-firstVisible:outline flex items-center justify-center'>
                      {parseInt(person.unread) > 99 ? '99+' : person.unread}
                    </p>
                  )}
                </div>
              </li>
            ),
        )}
      {allGroupConversations?.map((groups, i) => {
        return (
          currectTabValue() == groups?.groupType && (
            <li
              key={i}
              className={`flex gap-x-4 items-center  ${
                desktop ? '!py-1.5 !items-center' : 'py-2 border-b last:border-0'
              } `}
              onClick={() => clickGroupChat(groups, i)}
            >
              <span className='relative inline-block'>
                {groups?.groupImage && groups.groupImage != '' ? (
                  <img
                    className={`h-9 w-9 rounded-full object-cover object-center 
                      ${desktop && 'cursor-pointer'}`}
                    src={groups.groupImage}
                    alt='user-profile'
                  />
                ) : (
                  <Svgs
                    icon='peopleGroup'
                    fillClass='h-9 w-9 rounded-full object-cover object-center cursor-pointer'
                  ></Svgs>
                )}
              </span>
              <div className='min-w-0 flex items-center gap-x-4 cursor-pointer'>
                <p className='text-um font-semibold leading-6 text-gray-900 break-all'>{groups?.name}</p>

                {parseInt(groups?.unreadMessageCount) > 0 && (
                  <p
                    type='button'
                    className='rounded-full h-6 w-6 bg-hexitime-primaryRed p-1 text-[0.75rem] text-white shadow-sm focus-firstVisible:outline flex items-center justify-center'
                  >
                    {parseInt(groups.unreadMessageCount) > 99 ? '99+' : groups.unreadMessageCount}
                  </p>
                )}
              </div>
            </li>
          )
        );
      })}
    </ul>
  );
};

export default ChatUserListing;
