import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Api } from '../../../api';
import PrimaryButton from '../../../component/common/Buttons/PrimaryButton';
import Loader from '../../../component/common/Loader';
import { getLocalStorageItem, isValidFileSize, successToast, userData } from '../../../utils/helper';
import ConfirmPopup from '../../../component/common/ConfirmPopup';
import CommonInput from '../../../component/common/Input/CommonInput';
import SearchableSelect from '../../../component/common/SearchableSelect';
import { chatGroupVariableValidation } from '../../../validation/chatGroupVariableValidation';
import { db } from '../../../firebase';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
  getDoc,
} from 'firebase/firestore';
import toast from 'react-hot-toast';
import { ReactComponent as PeopleGroup } from '../../../assets/images/people_group.svg';
import axios from 'axios';

const ChatGroupCreateModal = ({ open, setOpen, groupData }) => {
  const userD = userData();
  const [form, setForm] = useState({
    name: '',
    membersList: [],
    image: '',
  });
  const [error, setError] = useState({
    name: '',
    membersList: '',
    image: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [loader, setLoader] = useState(false);
  const [fullLoader, setFullLoader] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [removeUsers, setRemoveUsers] = useState([]);
  const [createConfirmOpen, setCreateConfirmOpen] = useState(false);
  const uploadImgRef = useRef();
  const [groupImage, setGroupImage] = useState('');
  const [groupImageUrl, setGroupImageUrl] = useState('');
  useEffect(() => {
    if (groupData?.groupId) {
      setSelectedUsers(() => {
        return groupData?.members
          ?.map((member) => {
            if (member?.id != userD?.user_id) {
              return {
                id: Number(member.id),
                name: member.name,
                checked: true,
              };
            }
          })
          .filter(Boolean);
      });
      setForm({
        name: groupData?.groupName,
        membersList: groupData?.members
          .map((member) => {
            if (member?.id != userD?.user_id) {
              return {
                id: Number(member.id),
                name: member.name,
              };
            }
          })
          .filter(Boolean),
        image: groupData?.groupImage,
      });
    }
    setGroupImageUrl(groupData?.groupImage);
  }, [groupData?.groupId]);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      membersList: selectedUsers,
    }));
    setError((prev) => ({
      ...prev,
      membersList: '',
    }));
  }, [selectedUsers]);

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setError((prev) => ({
      ...prev,
      [e.target.name]: '',
    }));
  };

  const checkGroupNameAlreadyExist = async (name) => {
    const groupRef = collection(db, 'groups');
    const q = query(groupRef, where('name', '==', name));
    const groupQuerySnapshot = await getDocs(q);
    const docIds = groupQuerySnapshot.docs.map((doc) => {
      return doc.id;
    });

    return docIds?.length > 0 ? docIds[0] : false;
  };

  const handleUserListing = () => {
    setLoader(true);
    Api.getAllUserOfOrg(page, 10, searchTerm)
      .then((res) => {
        if (res?.data?.meta?.code == 200) {
          const newList = res?.data?.detail?.whitelabel_org_users_list?.map((user) => {
            return {
              name: user?.profile?.first_name + ' ' + user?.profile?.last_name,
              id: user?.user_id,
              profile: user?.profile?.profile_picture,
              is_active: user?.active,
              selected: false,
            };
          });
          if (usersList?.length > 0) {
            setUsersList((userList) => [...userList, ...newList]);
          } else {
            setUsersList(newList);
          }
          setTotalUser(res?.data?.detail?.count);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (page > 1) handleUserListing();
  }, [page]);

  const updateGroup = async (groupName, newAddedMembers, removedMembers, intialUsers, newGroupImage, groupType) => {
    try {
      if (!groupName && !newAddedMembers?.length && !removeUsers?.length && !groupImage) {
        return;
      }
      const docRef = doc(db, 'groups', groupData?.groupId);
      let groupMembersUpdate = intialUsers;
      const userRef = collection(db, 'users');

      if (newAddedMembers.length > 0) {
        groupMembersUpdate = groupMembersUpdate.concat(newAddedMembers);
      }

      if (removedMembers.length > 0) {
        groupMembersUpdate = groupMembersUpdate.filter(
          (member) => !removedMembers.some((userR) => userR.id == member.id),
        );
      }

      await updateDoc(docRef, {
        members: groupMembersUpdate,
        name: groupName || groupData?.groupName,
        updatedAt: Date.now(),
        groupImage: groupImage ? newGroupImage : groupData?.groupImage,
      });

      // Add groups details in user collection for new Addded Members
      if (newAddedMembers.length > 0) {
        const ids = newAddedMembers.map((item) => String(item?.id));
        const q = query(userRef, where('uid', 'in', ids));
        const userQuerySnapshot = await getDocs(q);
        const DocsArray = userQuerySnapshot.docs.map((doc) => doc?.id);

        const updateUserPromises = DocsArray.map(async (docId) => {
          const userDocRef = doc(db, 'users', docId);

          await updateDoc(userDocRef, {
            groups: arrayUnion({
              id: groupData?.groupId,
              name: groupName || groupData?.groupName,
              unreadMessageCount: 1,
              updatedAt: Timestamp.fromDate(new Date()),
              groupImage: groupImage ? newGroupImage : groupData?.groupImage,
              groupType: groupType,
            }),
          });
        });
        await Promise.all(updateUserPromises);
      }

      // Remove groups details in user collection for removed Members
      if (removedMembers.length > 0) {
        const ids = removedMembers.map((item) => String(item?.id));
        const q = query(userRef, where('uid', 'in', ids));
        const userQuerySnapshot = await getDocs(q);
        const DocsArray = userQuerySnapshot.docs.map((doc) => doc?.id);

        const removeUserPromises = DocsArray.map(async (docId) => {
          const userDocRef = doc(db, 'users', docId);
          const docSnap = await getDoc(userDocRef);
          const userDetails = docSnap.data();

          const updatedGrp = userDetails?.groups?.filter((grp) => grp.id != groupData?.groupId);

          await updateDoc(userDocRef, {
            groups: updatedGrp,
          });
        });
        await Promise.all(removeUserPromises);
      }

      // update name in users collection if changed
      if (groupName || newAddedMembers?.length || removeUsers?.length || groupImage) {
        // updatedUsers = intialUsers - newAddedMembers - removedMembers
        let remainingUsersIds = intialUsers.filter((user) => {
          return (
            !newAddedMembers.some((newUser) => Number(newUser.id) == Number(user.id)) &&
            !removedMembers.some((removedUser) => Number(removedUser.id) == Number(user.id))
          );
        });
        remainingUsersIds = remainingUsersIds.map((user) => String(user.id));

        if (remainingUsersIds.length > 0) {
          const q = query(userRef, where('uid', 'in', remainingUsersIds));
          const userQuerySnapshot = await getDocs(q);
          const DocsArray = userQuerySnapshot.docs.map((doc) => doc?.id);

          const updateUserPromises = DocsArray.map(async (docId) => {
            const userDocRef = doc(db, 'users', docId);

            const docSnap = await getDoc(userDocRef);
            const userDetails = docSnap.data();
            const updatedGroups = userDetails?.groups?.map((grp) => {
              if (grp.id == groupData?.groupId) {
                return {
                  ...grp,
                  name: groupName || groupData?.groupName,
                  groupImage: groupImage ? newGroupImage : groupData?.groupImage,
                  unreadMessageCount: grp.unreadMessageCount + 1,
                  updatedAt: Timestamp.fromDate(new Date()),
                };
              }
              return grp;
            });

            await updateDoc(userDocRef, {
              groups: updatedGroups,
            });
          });
          await Promise.all(updateUserPromises);
        }
      }

      const profilePic = getLocalStorageItem('profilePicture');
      const profile = profilePic && profilePic != 'null' ? profilePic : null;
      // add doc for edit msg in group
      await addDoc(collection(db, 'groups', groupData?.groupId, 'conversations'), {
        createdAt: Date.now(),
        updatedAt: Date.now(),
        senderId: userD?.user_id?.toString(),
        senderName: userD?.user_name,
        senderProfile: profile,
        text: `${userD?.user_name} has updated group`,
      });
    } catch (error) {
      toast.error('Failed to update group');
    }
  };

  const clearAllStates = () => {
    setRemoveUsers([]);
    setSelectedUsers([]);
    setForm({
      name: '',
      membersList: [],
      image: '',
    });
  };

  const handleEditOrCreate = async () => {
    try {
      const { errors, isValid } = chatGroupVariableValidation(form);
      if (!isValid) {
        setError(errors);
        return;
      }
      if (groupData?.groupId && !createConfirmOpen) {
        setCreateConfirmOpen(true);
        return;
      }

      const response = await checkGroupNameAlreadyExist(form?.name);
      if (response && response != groupData?.groupId) {
        setError((prev) => {
          return { ...prev, name: 'Group name already exist.' };
        });
        return;
      }

      // store the group information in the firebase db
      setFullLoader(true);
      if (groupData?.groupId) {
        let newGroupImage = '';
        if (groupImage) {
          // if groupImage has value that indicates image is updated
          const payload = {
            fileName: groupImage.name,
            fileType: groupImage.type,
          };
          const res = await Api.getGroupProfilePresignedUrlDetail(payload);
          if (res.data.meta.code === 200) {
            await axios.put(res?.data?.detail?.[0]?.presigned_url, groupImage, {
              headers: {
                'Content-Type': groupImage.type,
              },
            });
            newGroupImage = res?.data?.detail?.[0]?.file_url;
            form.image && (await Api.deleteGroupProfile(form.image));
          }
        }

        let updatedUsersFromFb,
          isNameChange = false;

        const docRef = doc(db, 'groups', groupData?.groupId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          updatedUsersFromFb = docSnapshot.data();
        }

        if (updatedUsersFromFb?.name != form?.name) {
          isNameChange = form?.name;
        }

        const newAddedMembers = form?.membersList.filter(
          (user) => !updatedUsersFromFb?.members.some((fbUsers) => fbUsers.id == user.id),
        );

        if (updatedUsersFromFb?.groupType == 'panelReview' && newAddedMembers?.length > 0) {
          const newAddedIds = newAddedMembers.map((user) => user?.id.toString());

          const response = await Api.sendMailForPanelReview({
            panel_member_ids: newAddedIds,
          });

          if (response?.data?.meta?.code == 200) {
            await updateGroup(
              isNameChange,
              newAddedMembers,
              removeUsers,
              updatedUsersFromFb?.members,
              newGroupImage,
              updatedUsersFromFb?.groupType,
            );
          }
        } else {
          await updateGroup(
            isNameChange,
            newAddedMembers,
            removeUsers,
            updatedUsersFromFb?.members,
            newGroupImage,
            updatedUsersFromFb?.groupType,
          );
        }

        setFullLoader(false);
        successToast('Group Updated Successfully', 2000);
        clearAllStates();
        setOpen(false);
      } else {
        const commonLogic = async (imageUrl) => {
          const groupCollecRef = collection(db, 'groups');
          const docRef = await addDoc(groupCollecRef, {
            name: form.name,
            members: [
              ...form.membersList.map((data) => ({
                name: data.name,
                id: data?.id?.toString(),
              })),
              { name: userD.user_name, id: userD?.user_id },
            ],
            createdBy: userD?.user_id,
            updatedBy: userD?.user_id,
            groupImage: imageUrl,
            createdAt: Date.now(),
            updatedAt: Date.now(),
            groupType: 'general',
          });
          const newCollectionRef = collection(groupCollecRef, docRef.id, 'conversations');
          const profilePic = getLocalStorageItem('profilePicture');
          const profile = profilePic && profilePic != 'null' ? profilePic : null;
          await addDoc(newCollectionRef, {
            text: `${userD.user_name} has created and joined you in group`,
            senderId: userD?.user_id,
            senderProfile: profile,
            senderName: userD.user_name,
            createdAt: Date.now(),
            updatedAt: Date.now(),
          });
          const userRef = collection(db, 'users');
          const q = query(
            userRef,
            where('uid', 'in', [...form.membersList.map((user) => user.id?.toString()), userD?.user_id]),
          );
          const userQuerySnapshot = await getDocs(q);
          const updateGroupPromises = userQuerySnapshot.docs.map(async (docSnapshot) => {
            const userRef1 = docSnapshot.ref;
            return updateDoc(userRef1, {
              groups: arrayUnion({
                id: docRef.id,
                name: form.name,
                unreadMessageCount: 1,
                groupType: 'general',
                updatedAt: Timestamp.fromDate(new Date()),
                groupImage: imageUrl,
              }),
            });
          });
          await Promise.all(updateGroupPromises);
          setFullLoader(false);
          setOpen(false);
          successToast('Group Created Successfully', 2000);
        };
        if (groupImage) {
          const payload = {
            fileName: groupImage.name,
            fileType: groupImage.type,
          };
          const res = await Api.getGroupProfilePresignedUrlDetail(payload);
          if (res.data.meta.code === 200) {
            await axios.put(res?.data?.detail?.[0]?.presigned_url, groupImage, {
              headers: {
                'Content-Type': groupImage.type,
              },
            });
            await commonLogic(res?.data?.detail?.[0].file_url);
            form.image && (await Api.deleteGroupProfile(form.image));
          }
        } else {
          await commonLogic(form.image);
        }
      }
    } catch (error) {
      toast.error('something went wrong');
    } finally {
      setFullLoader(false);
    }
  };

  const imgHandler = (e) => {
    setError((pre) => ({
      ...pre,
      image: '',
    }));
    const { files } = e?.target;
    if (files && files.length > 0) {
      let fileName = files?.[0]?.name;
      let url = URL.createObjectURL?.(files?.[0]);
      setGroupImageUrl(url);
      if (!fileName?.match(/\.(jpg|jpeg|png)$/i)) {
        setError((prevState) => ({
          ...prevState,
          image: `only jpg,png and jpeg files are supported`,
        }));
      } else if (!isValidFileSize(files[0], 5 * 1024 * 1024)) {
        setError((prevState) => ({
          ...prevState,
          image: `The uploaded file is larger than the file size limit 5MB`,
        }));
      } else {
        setGroupImage(files[0]);
      }
    }
  };

  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-hidden'>
          <div className='flex min-h-full justify-center !p-4 text-center items-center sm:!p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform rounded-lg bg-white !px-4 !pb-4 !pt-5 text-left shadow-xl transition-all sm:!mt-12 w-full sm:max-w-lg sm:!p-6'>
                {fullLoader && <Loader />}
                <div className='flex items-start justify-between !pb-2 border-b rounded-t dark:border-gray-600'>
                  <Dialog.Title as='h3' className='text-xl font-semibold text-gray-900'>
                    {groupData?.groupId ? 'Update' : 'Create'} chat group
                  </Dialog.Title>

                  <button
                    type='button'
                    className='text-hexitime-textColor2 bg-hexitime-textColor4 hover:bg-hexitime-textColor1 rounded-full text-sm !p-1.5 ml-auto inline-flex items-center outline-none'
                    data-modal-hide='defaultModal'
                    onClick={() => setOpen(false)}
                  >
                    <XMarkIcon className='h-4 w-4 text-white' aria-hidden='true' />
                    <span className='sr-only'>Close modal</span>
                  </button>
                </div>

                <div className='mt-4 flex flex-col gap-y-4  '>
                  <div className='flex  gap-x-4 flex-col'>
                    <div className='flex items-center gap-1'>
                      <input
                        id='profilePicture'
                        name='profilePicture'
                        type='file'
                        className='sr-only hidden'
                        accept='image/*'
                        ref={uploadImgRef}
                        onChange={(e) => imgHandler(e)}
                      />
                      {groupImageUrl ? (
                        <img
                          src={groupImageUrl}
                          alt='profile-pic'
                          className='h-[60px] w-[60px] rounded-full border border-white object-center object-cover cursor-pointer'
                          onClick={() => uploadImgRef?.current?.click()}
                        />
                      ) : (
                        <div className='h-[60px] w-[60px] rounded-full border border-white object-center object-cover cursor-pointer'>
                          <PeopleGroup />
                        </div>
                      )}
                      <p
                        className='text-hexitime-primary underline cursor-pointer'
                        onClick={() => uploadImgRef?.current?.click()}
                      >
                        Change image
                      </p>
                    </div>
                    {error.image && (
                      <div>
                        <span className='error text-sm xl:text-base text-hexitime-primaryRed leading-[1px]'>
                          {error.image}
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    <CommonInput
                      id='groupName'
                      name='name'
                      value={form.name}
                      label='Group name'
                      error={error.name}
                      type='text'
                      onChange={handleChange}
                      placeholder='Enter Group Name'
                      isRequired={true}
                    />
                  </div>
                  <SearchableSelect
                    label='Users'
                    setSelectedList={setSelectedUsers}
                    data={usersList}
                    selectedList={selectedUsers}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    apiKey='users'
                    handleListing={handleUserListing}
                    setRemovedList={setRemoveUsers}
                    isDisabled={false}
                    showTop={true}
                    error={error?.membersList}
                    showProfile={true}
                    totalUser={totalUser}
                    setPage={setPage}
                    setUsersList={setUsersList}
                    isLoading={loader}
                    isRequired={true}
                  />
                </div>
                <div className='!my-5 flex justify-between'>
                  <button
                    type='button'
                    className='text-hexitime-primaryRed font-semibold text-base'
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>

                  <PrimaryButton
                    btnType='button'
                    btnText={groupData?.groupId ? 'Update' : 'Create'}
                    disabled={error.image || error.membersList || error.name ? true : false}
                    className='xsm:!w-[170px] !w-[auto] !px-[20px]'
                    onClick={handleEditOrCreate}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        {groupData?.groupId && createConfirmOpen && (
          <ConfirmPopup
            open={createConfirmOpen}
            setOpen={setCreateConfirmOpen}
            message={`Are you sure you want to edit group?`}
            setAccepted={handleEditOrCreate}
          />
        )}
      </Dialog>
    </Transition.Root>
  );
};

export default ChatGroupCreateModal;
