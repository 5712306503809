import React, { lazy, memo, Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RoutesFile from '../routes/RouteFile';
import PrivateRoute from './PrivateRoute';
import Loader from '../component/common/Loader';
import PropTypes from 'prop-types';
import TimeBank from '../container/TermsAndCondition/TimeBank';
import {
  ChatContext,
  fetchOrgInfo,
  getLocalStorageItem,
  getZitadelToken,
  handleGtmScriptAdd,
  removeLocalStorageItem,
  setLocalStorageItem,
  UserDetailsContext,
} from '../utils/helper';
import { Api } from '../api';
import { getCookieConsentValue, resetCookieConsentValue } from 'react-cookie-consent';
const NotFound = lazy(() => import('../container/404NotFound'));
const RegisterUserToZitadel = lazy(() => import('../container/Register/zitadel/InitiateRegister'));
const RegisterCompleted = lazy(() => import('../container/Register/zitadel/RegisterCompleted'));
const TermsOfUser = lazy(() => import('../container/TermsAndCondition/Termstouser'));
const PrivacyPolicy = lazy(() => import('../container/TermsAndCondition/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('../container/TermsAndCondition/CookiePolicy'));
const Login = lazy(() => import('../container/Login'));
const NetworkDetails = lazy(() => import('../container/Networks/Networks'));

const RoutesList = memo(() => {
  const [zitadelTokenValidateLoader, setZitadelTokenValidateLoader] = useState(true);
  const [themeApplyLoading, setThemeApplyLoading] = useState(true);
  const { setProfilePicture, setUsersData, setCredits, setCurrentScreenWidth } = useContext(UserDetailsContext);
  const { setMobileDevice } = useContext(ChatContext);

  const handleResize = () => {
    setCurrentScreenWidth(window.innerWidth);
    if (window.innerWidth < 1280) {
      setMobileDevice(true);
    } else {
      setMobileDevice(false);
    }
  };
  useEffect(() => {
    const tempFn = async () => {
      const token = getZitadelToken();
      const refreshToken = localStorage.getItem('refresh_token');
      if (token && refreshToken) {
        const jwtToken = localStorage.getItem('token');
        setZitadelTokenValidateLoader(true);
        const response = await Api.checkTokenValidity(token, refreshToken, jwtToken);
        if (!response) {
          sessionStorage.clear();
        }
        const tempCredits = {
          available: response?.data?.detail?.credits,
          freezed: response?.data?.detail?.freeze_credits,
        };
        setLocalStorageItem('credit', JSON.stringify(tempCredits));
        setCredits(tempCredits);
        const { email, user_id, user_name, user_type, is_step } = response?.data?.detail;
        setLocalStorageItem(
          'userData',
          JSON.stringify({
            email,
            user_id: user_id.toString(),
            user_name,
            user_type,
            is_step,
          }),
        );

        setUsersData({
          email,
          user_id: user_id.toString(),
          user_name,
          user_type,
          is_step,
        });
        setLocalStorageItem('profilePicture', response?.data?.detail?.profile_pic);
        setProfilePicture(response?.data?.detail?.profile_pic);
        setLocalStorageItem('zitadel_user_access_token', response?.data?.detail?.z_token?.access_token);
        setLocalStorageItem('refresh_token', response?.data?.detail?.z_token?.refresh_token);
        setLocalStorageItem('token', response?.data?.detail?.access_token);
        setZitadelTokenValidateLoader(false);
        const res2 = await fetchOrgInfo();
        setThemeApplyLoading(res2);
      } else {
        setZitadelTokenValidateLoader(false);
        setThemeApplyLoading(false);
      }
    };
    tempFn();
  }, []);

  useEffect(() => {
    const isConsent = getCookieConsentValue('ww-cookie');
    const cookiePermissionsGiven = getLocalStorageItem('cookiePermission');
    if (
      isConsent == 'true' &&
      cookiePermissionsGiven &&
      Object.keys(JSON.parse?.(cookiePermissionsGiven))?.length === 3
    ) {
      handleGtmScriptAdd(JSON.parse(cookiePermissionsGiven));
    } else {
      removeLocalStorageItem('cookiePermission');
      resetCookieConsentValue('ww-cookie');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('load', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Switch>
          {!getLocalStorageItem('token') && !getZitadelToken() && (
            <Route exact path='/network/:id' component={NetworkDetails} />
          )}
          {RoutesFile.map((itm, key) =>
            itm.private ? (
              zitadelTokenValidateLoader || themeApplyLoading ? (
                <Loader key={'1'} />
              ) : (
                <PrivateRoute
                  key={key}
                  exact={itm.exact}
                  path={itm.path}
                  name={itm.name}
                  onlyAdmin={itm.onlyAdmin}
                  component={itm.component}
                />
              )
            ) : (
              <Route key={key} exact={itm.exact} path={itm.path} name={itm.name} component={itm.component} />
            ),
          )}

          <Route exact path='/' component={Login} />
          <Route exact path='/whitelabel/:orgId/register' component={RegisterUserToZitadel} />
          <Route exact path='/whitelabel/user/register/completed' component={RegisterCompleted} />
          <Route exact path='/terms-of-use' component={TermsOfUser} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route exact path='/cookie-policy' component={CookiePolicy} />
          <Route exact path='/timebank-terms-and-conditions' component={TimeBank} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
});

RoutesList.propTypes = {
  isShow: PropTypes.any,
  setShow: PropTypes.any,
};

export default RoutesList;
