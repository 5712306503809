import React, { memo } from 'react';
import { render } from 'react-dom';
import { Toaster } from 'react-hot-toast';
import './assets/css/index.css';
import Routes from './routes';
import { UserDetailsProvider } from './context/UserDetailContext';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AuthProvider } from 'oidc-react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './container/ErrorFallback';

const MainApp = memo(() => {
  const oidcConfig = {
    authority: process.env.REACT_APP_ZITADEL_AUTHORITY,
    clientId: process.env.REACT_APP_ZITADEL_CLINENTID,
    responseType: 'code',
    redirectUri: `${process.env.REACT_APP_BASE_URL}/whitelabel/user/register/completed`,
    autoSignIn: false,
    scope: 'openid profile email offline_access ',
  };

  return (
    <AuthProvider {...oidcConfig}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => (window.location.href = `${process.env.REACT_APP_BASE_URL}/home`)}
      >
        <UserDetailsProvider>
          <Routes />
          <Toaster />
        </UserDetailsProvider>
      </ErrorBoundary>
    </AuthProvider>
  );
});

const rootElement = document.getElementById('root');

render(<MainApp />, rootElement);
