export const chatGroupVariableValidation = (form) => {
  const errors = {};
  if (!form.name || form.name.trim().length === 0) {
    errors.name = 'Required field';
  }
  if (form.name.trim().length > 150) {
    errors.name = 'More than 150 characters';
  }
  if (form.membersList.length === 0) {
    errors.membersList = 'At least one member is required';
  }
  return { errors, isValid: Object.keys(errors).length === 0 };
};
